import React from 'react';
import {
  FormControl,
  MenuItem,
  Grid,
  Tooltip,
  CircularProgress,
  Typography,
  Box,
  makeStyles,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react-lite';
import { capitalize, checkNullParamater, formatStringForReadbility } from '../../../utils/ui.utils';
import {
  PORTAL_TARGETS,
  autonomyNavigationModes,
  attachmentTypes,
  robotDeploymentTargetTypes,
  TOOL_OFFSET_DIRECTION,
  INTER_ROW_TYPE
} from '../../../utils/constants';
import DeviationConfiguration from './DeviationConfiguration';
import StalledAutonomyConfigurations from './StuckAutonomyConfigurations';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  sectionHeaderContainer: {
    boxSizing: 'border-box',
    margin: '10px 0px'
  },
  sectionHeaderText: {
    fontSize: '1.2rem'
  },
  autonomyMotorSettingsContainer: {
    marginTop: '20px',
    border: '2px solid grey',
    boxSizing: 'border-box',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  sectionContainer: {
    padding: '3px',
    borderRight: '2px solid #bebfc2',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      border: '1px solid #bebfc2 '
    }
  },
  switch: {
    width: '100%',
    justifyContent: 'space-between',
    marginLeft: '4px'
  }
}));

/**
 * This component is responsible for managing Autonomy, Motor and Attachment settings on Manage Robots
 * @param {Object} classes Makestyle instance for styling components
 * @param {Boolean} formError Checks if form has error. Errors range from empty fields to invalid values
 * @param {Object} uiParameters An object containing ui state values to handle user interaction
 * @param {Function} handleUiParametersChange Handles changes to values uiParameters state
 * @param {Object} setOpenRobotAttributesDialog Handles rendering tooltip for Robot Attributes.
 * @param {Boolean} isSelectedRobotConnectedROS Checks if the robot currently being configured has an active ROS connection
 * @param {Object} isSelectedRobotInUse Checks if robot is currently being used for autonomous operations
 * @param {Function} robotDeploymentTarget Robot's software deployment target. Current options are Dev, Stage, and Prod
 * @param {Object} setrobotDeploymentTarget Sets robot's deployment
 * @param {Function} autonomyNavigationControllerOptions State variable containing motion controller options
 * @param {Object} autonomyAttachmentControllerOptions State setter for motion controller options
 * @returns {Component} React Component
 */
const AutonomyConfigurations = observer(
  ({
    isSelectedRobotConnectedROS,
    isSelectedRobotInUse,
    robotDeploymentTarget,
    setrobotDeploymentTarget,
    formError,
    autonomyNavigationControllerOptions,
    autonomyAttachmentControllerOptions,
    uiParameters,
    handleUiParametersChange,
    setUiParameters,
    portalTarget
  }) => {
    const classes = useStyles();

    return (
      <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
        {/* Robot Deployment target determines the deployment container for swapbot */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <Tooltip
            title={
              <Typography fontSize={30}>
                <em>Some Development Branch</em> means that experimental code is deployed on the robot. For more information on our software
                development cycle, see the Robot Configurations Show Additional Information info circle.
              </Typography>
            }
          >
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                required
                id="software-branch"
                select
                label="Robot Deployment Target"
                variant="outlined"
                disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
                style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
                value={robotDeploymentTarget}
                onChange={(event) => {
                  setrobotDeploymentTarget(event.target.value);
                }}
                error={!robotDeploymentTarget && formError}
              >
                {robotDeploymentTargetTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Autonomy Navigation mode settings */}
        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <Tooltip
            title={
              <Typography fontSize={30}>
                Teach & Repeat is the default mode, Route Following is for infinitely repeating specifically designed paths and Route
                Following is used for repeating paths created via the Route Following page..
              </Typography>
            }
          >
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                required
                id="autonomy-navigation-mode"
                name="autonomyNavigationMode"
                select
                label="Autonomy navigation mode"
                variant="outlined"
                disabled={checkNullParamater(uiParameters.autonomyNavigationMode) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
                style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
                value={uiParameters.autonomyNavigationMode}
                onChange={(event) => {
                  handleUiParametersChange(event, false, false);
                }}
                error={!uiParameters.autonomyNavigationMode && formError}
              >
                {autonomyNavigationModes.map((mode) => (
                  <MenuItem key={mode.id} value={mode.id}>
                    {mode.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Autonomy navigation settings */}

        <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="autonomy-navigation-controller"
              select
              label="Autonomy Navigation Controller"
              variant="outlined"
              disabled={
                checkNullParamater(uiParameters.autonomyNavigationController) || !isSelectedRobotConnectedROS || isSelectedRobotInUse
              }
              style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
              value={uiParameters.autonomyNavigationController}
              onChange={(event) => {
                handleUiParametersChange(event, false, false);
              }}
              error={!uiParameters.autonomyNavigationController && formError}
            >
              {autonomyNavigationControllerOptions.map((type) => (
                <MenuItem key={type} value={type}>
                  {formatStringForReadbility(type)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        {/* Robot Attachment setting  */}
        <Tooltip title={<Typography fontSize={30}>This modifies the robots attachment type</Typography>}>
          <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                required
                name="attachmentType"
                id="attachment-type"
                select
                label="Attachment type"
                variant="outlined"
                disabled={checkNullParamater(uiParameters.attachmentType) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
                style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
                value={uiParameters.attachmentType}
                onChange={(event) => {
                  handleUiParametersChange(event, false, false);
                }}
                error={!uiParameters.attachmentType && formError}
              >
                {attachmentTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Tooltip>
        {/* Autonomy Attachment controller setting  */}
        <Tooltip title={<Typography fontSize={30}>This modifies the robots attachment settings</Typography>}>
          <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                required
                name="autonomyAttachmentController"
                id="attachment-controller-type"
                select
                label="Attachment Controller Type"
                variant="outlined"
                disabled={
                  checkNullParamater(uiParameters.autonomyAttachmentController) || !isSelectedRobotConnectedROS || isSelectedRobotInUse
                }
                style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
                value={uiParameters.autonomyAttachmentController}
                onChange={(event) => {
                  handleUiParametersChange(event, false, false);
                }}
                error={!uiParameters.autonomyAttachmentController && formError}
              >
                {autonomyAttachmentControllerOptions.map((type) => (
                  <MenuItem key={type} value={type}>
                    {formatStringForReadbility(type)}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Tooltip>
        {/* Robot portal target deployment (prod, stage, dev) */}
        <Grid
          item
          xs={12}
          md={6}
          style={{ opacity: (!portalTarget || !isSelectedRobotConnectedROS) && '0.5' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Tooltip title={<Typography fontSize={30}>This changes what portal the robot appears on.</Typography>}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                required
                id="portal-target"
                name="portalTarget"
                select
                label="Portal Deployment Target"
                variant="outlined"
                value={portalTarget ?? 'loading'}
                disabled={!portalTarget || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
                onChange={(event) => {
                  handleUiParametersChange(event, false, false);
                }}
              >
                {portalTarget ? (
                  Object.entries(PORTAL_TARGETS).map(([key, name]) => (
                    <MenuItem key={key} value={key}>
                      {capitalize(name)}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="loading">
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <CircularProgress color="inherit" size="1.125rem" />
                    </Grid>
                  </MenuItem>
                )}
              </TextField>
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Attachment offset direction */}
        <Tooltip title={<Typography fontSize={30}>This changes which direction the attachment offsets to.</Typography>}>
          <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                required
                name="attachmentOffsetDirection"
                id="attachment-offset-config"
                select
                label="Attachment Offset Direction"
                variant="outlined"
                disabled={
                  checkNullParamater(uiParameters.attachmentOffsetDirection) || !isSelectedRobotConnectedROS || isSelectedRobotInUse
                }
                style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
                value={uiParameters.attachmentOffsetDirection}
                onChange={(event) => {
                  handleUiParametersChange(event, false, false);
                }}
                error={!uiParameters.autonomyAttachmentController && formError}
              >
                {TOOL_OFFSET_DIRECTION.map((direction) => (
                  <MenuItem key={direction.name} value={direction.value}>
                    {formatStringForReadbility(direction.name)}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Tooltip>
        {/* Autonomy inter-row type */}
        <Tooltip title={<Typography fontSize={30}>This changes inter-row direction during autonomy.</Typography>}>
          <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <TextField
                required
                name="interrowType"
                id="interrowType"
                select
                label="Inter-row Type"
                variant="outlined"
                disabled={checkNullParamater(uiParameters.interrowType) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
                style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
                value={uiParameters.interrowType}
                onChange={(event) => {
                  handleUiParametersChange(event, false, false);
                }}
                error={!uiParameters.autonomyAttachmentController && formError}
              >
                {INTER_ROW_TYPE.map((type) => (
                  <MenuItem key={type.name} value={type.value}>
                    {formatStringForReadbility(type.name)}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Tooltip>
        {/** Autonomy & Motors Setting */}
        <Box className={classes.sectionHeaderContainer}>
          <Typography variant="subtitle1" className={classes.sectionHeaderText}>
            Attachment & Motors Settings
          </Typography>
        </Box>
        <Grid item xs={12} className={classes.autonomyMotorSettingsContainer} container>
          {/* Enable Manual Blade  */}
          <Grid item md={3} className={classes.sectionContainer}>
            <Tooltip title={<Typography fontSize={30}>When enabled, blades operate in manual mode during autonomy operations.</Typography>}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    name="enableManualBladesDuringAutonomy"
                    checked={uiParameters.enableManualBladesDuringAutonomy}
                    onChange={(event) => {
                      handleUiParametersChange(event, true);
                    }}
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                  />
                }
                labelPlacement="start"
                label="Enable Manual Blades"
                disabled={
                  checkNullParamater(uiParameters.enableManualBladesDuringAutonomy) || !isSelectedRobotConnectedROS || isSelectedRobotInUse
                }
                className={classes.switch}
                style={{
                  opacity: !uiParameters.enableManualBladesDuringAutonomy ? '0.5' : '0.7'
                }}
              />
            </Tooltip>
            {/* Enable Autonomous Blade  */}
            <Tooltip
              title={<Typography fontSize={30}>When enabled, blades operate in autonomous mode during autonomy operations..</Typography>}
            >
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    name="enableAutonomousBladesDuringAutonomy"
                    checked={uiParameters.enableAutonomousBladesDuringAutonomy}
                    onChange={(event) => {
                      handleUiParametersChange(event, true);
                    }}
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                  />
                }
                labelPlacement="start"
                label="Enable Autonomous Blades"
                className={classes.switch}
                style={{
                  opacity: !uiParameters.enableAutonomousBladesDuringAutonomy ? '0.5' : '0.7'
                }}
                disabled={
                  !isSelectedRobotConnectedROS || isSelectedRobotInUse || checkNullParamater(uiParameters.enableManualBladesDuringAutonomy)
                }
              />
            </Tooltip>
          </Grid>
          <Grid item md={3} className={classes.sectionContainer}>
            {/* Enable autonomous attachment control */}
            <Tooltip title={<Typography fontSize={30}>When enabled, attachment control will be autonomous.</Typography>}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    name="enableAutonomousAttachmentControl"
                    checked={uiParameters.enableAutonomousAttachmentControl}
                    onChange={(event) => {
                      const { name, checked } = event.target;
                      const formattedValue = checked ? 1 : 0;
                      setUiParameters((previous) => ({
                        ...previous,
                        [name]: formattedValue
                      }));
                    }}
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                  />
                }
                labelPlacement="start"
                label="Enable Autonomous Attachment Control"
                disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
                className={classes.switch}
                style={{
                  opacity: !uiParameters.enableAutonomousAttachmentControl ? '0.5' : '0.7'
                }}
              />
            </Tooltip>
            <Tooltip title={<Typography fontSize={30}>When enabled, robot's cutting speed is determined by mottor current.</Typography>}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    name="dynamicMowingSpeed"
                    checked={uiParameters?.dynamicMowingSpeed}
                    onChange={(event) => {
                      handleUiParametersChange(event, true);
                    }}
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                  />
                }
                labelPlacement="start"
                label="Enable Dynamic Mowing Speed"
                disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
                className={classes.switch}
                style={{
                  opacity: !uiParameters.dynamicMowingSpeed ? '0.5' : '0.7'
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item md={3} className={classes.sectionContainer}>
            {/* Enable inter-row hopping */}
            <Tooltip title={<Typography fontSize={30}>When enabled, robot will execute inter-row hopping.</Typography>}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    name="enableInterRowHopping"
                    checked={uiParameters.enableInterRowHopping}
                    onChange={(event) => {
                      handleUiParametersChange(event, true);
                    }}
                    color="primary"
                    style={{ marginLeft: '1px' }}
                  />
                }
                labelPlacement="start"
                label="Enable Inter-Row Hopping"
                disabled={checkNullParamater(uiParameters.enableInterRowHopping) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
                className={classes.switch}
                style={{
                  opacity: !uiParameters.enableInterRowHopping ? '0.5' : '0.7'
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item md={3} className={classes.sectionContainer}>
            {/* Enable overworked motors check */}
            <Tooltip
              title={
                <Typography fontSize={30}>
                  When enabled, robot will routinely check if motors are being worked past their limits.
                </Typography>
              }
            >
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    name="overworkedMotorsCheck"
                    checked={uiParameters.overworkedMotorsCheck}
                    onChange={(event) => {
                      handleUiParametersChange(event, true);
                    }}
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                  />
                }
                labelPlacement="start"
                label="Enable Overworked Motors Check"
                disabled={checkNullParamater(uiParameters.overworkedMotorsCheck) || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
                className={classes.switch}
                style={{
                  opacity: !uiParameters.overWorkedMotorsCheck ? '0.5' : '0.7'
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <Box className={classes.sectionHeaderContainer}>
          <Typography variant="subtitle1" className={classes.sectionHeaderText}>
            Deviation Settings
          </Typography>
        </Box>
        {/** Deviation Settings */}
        <DeviationConfiguration
          isSelectedRobotConnectedROS={isSelectedRobotConnectedROS}
          isSelectedRobotInUse={isSelectedRobotInUse}
          uiParameters={uiParameters}
          handleUiParametersChange={handleUiParametersChange}
          setUiParameters={setUiParameters}
        />
        <Box className={classes.sectionHeaderContainer}>
          <Typography variant="subtitle1" className={classes.sectionHeaderText}>
            Stalled Autonomy Settings
          </Typography>
        </Box>
        {/** Stuck Autonomy Settings */}
        <StalledAutonomyConfigurations
          isSelectedRobotConnectedROS={isSelectedRobotConnectedROS}
          isSelectedRobotInUse={isSelectedRobotInUse}
          uiParameters={uiParameters}
          handleUiParametersChange={handleUiParametersChange}
          setUiParameters={setUiParameters}
        />
      </Grid>
    );
  }
);

export default AutonomyConfigurations;
