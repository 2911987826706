/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { makeStyles, Dialog, DialogContent, DialogTitle, IconButton, Box, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStores } from '../../../store/root/root.store';
import { buildTopicAndPayloadForMqttEstop, formatStringForReadbility, isDevMode } from '../../../utils/ui.utils';
import ConnectionErrorDialog from '../../dialogs/connection-error.dialog';
import { MultiRobotsRemoteGuardianRepeat } from '../../../pages/multi-robots-remote-guardian-repeat';
import { MqttClient } from '../../../realtimeClient';
import { formatSubrows } from '../../../utils/format-solar-row';
import {
  NOT_STARTED,
  COMPLETED,
  IN_PROGRESS,
  ERROR_TIME_THRESHOLD,
  TOGGLE_AUTONOMOUS_BLADES,
  PS4_PARAMETERS,
  EXEC_SUSPENDED,
  IN_USE,
  ROBOT_CONTROL_MODES,
  AVAILABLE,
  STATUS_MAP,
  BATTERIES_WARNING_LEVELS,
  ESTOP_TYPES
} from '../../../utils/constants';
import ActionsDialog from '../../dialogs/actions.dialog';
import LoadingDialog from '../../dialogs/loading-dialog.dialog';
import { EStopIndicator } from '../e-stop-indicator';
import { ControlPanel } from './control-panel.component';
import VideoStreamMultiPageView from '../video-stream-multi-page-view.component';
import BatteryAlert from '../../core/battery-level-warning-component';
import ConfirmActionDialog from '../../dialogs/confirm-action.dialog';
import AutonomyControlButtons from './autonomy-control-buttons';
import MultiRobotsViewControls from './multi-robot-view-control-component';
import { updateSubrow, getSubrowMetaData } from '../../../services/api/subrows.service';
import { getSolarMapData } from '../../../services/api/robots.service';
import {
  isValidRobotPosition,
  isRobotAutonomyReady,
  switchControlMode,
  fetchSubBlockSubRows,
  verifySelectedSubrow
} from '../../../utils/multi-robots.utils';
import { useRobotConnectionService } from '../../../hooks/useRobotConnectionService';

const useStyles = makeStyles((theme) => ({
  '@keyframes flashYellowAnimation': { '0%, 100%': { borderColor: 'transparent' }, '50%': { borderColor: 'yellow' } },
  '@keyframes flashRedAnimation': { '0%, 100%': { borderColor: 'transparent' }, '50%': { borderColor: 'red' } },
  flashYellow: { animation: '$flashYellowAnimation 1s infinite' },
  flashRed: { animation: '$flashRedAnimation 1s infinite' },
  solidGreen: { borderColor: '#30E206' },

  container: {
    background: 'rgba(32, 32, 32, 1.0)',
    marginTop: '10px',
    marginRight: '3px',
    '&:nth-child(even)': {
      marginLeft: '5px'
    },
    position: 'relative',
    width: '49.5%',
    boxSizing: 'border-box',
    height: '49.5%',
    maxHeight: '595px',
    maxWidth: '49.5%',
    border: '4px solid #30E206',
    borderRadius: '7px',
    display: 'grid',
    gridTemplateColumns: '0.7fr 0.1fr 2.7fr',
    gridTemplateRows: '0.0fr 2.7fr 0.4fr',
    gap: '0px 0px',
    gridAutoFlow: 'row',
    gridTemplateAreas: `"controlPanel controls ."
      "controlPanel controls videoArea"
      "controlPanel notification notification"`
  },
  controlPanel: {
    position: 'relative',
    gridArea: 'controlPanel',
    backgroundColor: 'rgba(32, 32, 32, 1.0)',
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  notification: { gridArea: 'notification', maxWidth: '100%', maxHeight: '100%', height: '100%', width: '100%' },
  controls: {
    gridArea: 'controls',
    backgroundColor: 'rgba(32, 32, 32, 1.0)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  videoArea: {
    position: 'relative',
    gridArea: 'videoArea',
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    border: '3px solid grey'
  },
  customNotificationText: {
    fontWeight: 'bold',
    fontSize: '12px'
  },
  notificationBox: {
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9C47D',
    height: '100%',
    width: '100%',
    padding: '5px',
    borderRadius: '5px',
    overflow: 'auto',
    overflowY: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

const RobotControlView = observer(
  ({
    serialNumber,
    shouldRobotPaused,
    solarSubRowsData,
    setShouldRobotPaused,
    shouldPauseAllRobots,
    setShouldPauseAllRobots,
    RGOpened,
    setRGOpened,
    cords,
    selectedProperty,
    swEstopAllRobots,
    setSwEstopAllRobots
  }) => {
    const { applicationStore, MultiRobotsStore, blocksStore, subBlocksStore, chaperonePropertyStore, regionsStore } = useStores();
    const isMountedRef = useRef(null);
    const [start, setStart] = useState(true);
    const [resume, setResume] = useState(false);
    const [pause, setPause] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('');
    const [loadingCountdownMessage, setLoadingCountdownMessage] = useState('');
    const [loadingCountdownTime, setLoadingCountdownTime] = useState(0);
    const [resetRobot, setResetRobot] = useState(false);
    const [controlMode, setControlMode] = useState('manual');
    const [openRemoteGuardian, setOpenRemoteGuardian] = useState(false);
    const [solarSubRows, setSolarSubRows] = useState([]);
    const [isStarted, setIsStarted] = useState(false);
    const [userRobot, setUserRobot] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userConfirmedBatteryWarning, setUserConfirmedBatteryWarning] = useState(false);
    const isRightOffset = MultiRobotsStore.robots.get(serialNumber)?.isRightOffsetDeck;
    const robotConnectionService = useRef(null);
    const robotConnection = useRef(null);
    const classes = useStyles();
    const currentSubrow = useRef(null);
    const robot = MultiRobotsStore.robots.get(serialNumber);
    const [selectedBlockId, setSelectedBlockId] = useState(blocksStore.getSelectedBlock()?.id);
    const [selectedSubBlockId, setSelectedSubBlockId] = useState(subBlocksStore.getSelectedSubBlock()?.id);
    const [selectedPathType, setSelectedPathType] = useState(MultiRobotsStore.getSelectedPathType(serialNumber));
    const [selectedPropertyId, setSelectedPropertyId] = useState(chaperonePropertyStore.getSelectedProperty()?.id);
    const [selectedRegionId, setSelectedRegionId] = useState(regionsStore.getSelectedRegion()?.id);
    const robotGpsFixStatus = MultiRobotsStore.robots.get(serialNumber)?.gpsFixStatus;
    const robotStableLocalization = MultiRobotsStore.robots.get(serialNumber)?.stableLocalization;
    const robotInsStatus = MultiRobotsStore.robots.get(serialNumber)?.stableLocalization ? 'Calibrated' : 'Drive to Calibrate';
    const robotLat = MultiRobotsStore.robots.get(serialNumber)?.lat;
    const robotLng = MultiRobotsStore.robots.get(serialNumber)?.lng;
    const robotLastRobotStateReceived = MultiRobotsStore.robots.get(serialNumber)?.lastRobotStateReceived;
    const robotCurrentHeadingRad = MultiRobotsStore.robots.get(serialNumber)?.currentHeadingRad;
    const robotControlMode = MultiRobotsStore.robots.get(serialNumber)?.controlMode;
    const robotPreviousWpsState = MultiRobotsStore.robots.get(serialNumber)?.previousWpsState;
    const robotPreviousControlMode = MultiRobotsStore.robots.get(serialNumber)?.previousControlMode;
    const robotWpsState = Number(MultiRobotsStore.robots.get(serialNumber)?.wpsState);
    const robotIsDoneRepeatingMission = MultiRobotsStore.robots.get(serialNumber)?.isDoneRepeatingMission;
    const robotIsDoneRepeatingPath = MultiRobotsStore.robots.get(serialNumber)?.isDoneRepeatingPath;
    const isTeleopsStreamingEnabled = MultiRobotsStore.robots.get(serialNumber)?.isTeleopsStreamingEnabled;
    const robotEstopState = MultiRobotsStore.robots.get(serialNumber)?.estopState;
    const robotSensingEdgeState = MultiRobotsStore.robots.get(serialNumber)?.sensingEdgeState;
    const robotSafetyStopState = MultiRobotsStore.robots.get(serialNumber)?.safetyStop;
    const operatingSubrowId = MultiRobotsStore.robots.get(serialNumber)?.currentRow;
    const operatingSubrowName = MultiRobotsStore.robots.get(serialNumber)?.currentRowName;
    const selectedSolarSubRows = MultiRobotsStore.robots.get(serialNumber)?.selectedSolarSubRows;
    const robotSwEstopState = MultiRobotsStore.robots.get(serialNumber)?.swEstopState;
    const highLatency = MultiRobotsStore.robots.get(serialNumber)?.highLatency;
    const staleTeleopsJoy = MultiRobotsStore.robots.get(serialNumber)?.staleTeleopsJoy;
    const latency = MultiRobotsStore.robots.get(serialNumber)?.latency;
    const safetyEnabled = MultiRobotsStore.robots.get(serialNumber)?.safetyEnabled;
    const auotnomyStoppedDeviated = MultiRobotsStore.robots.get(serialNumber)?.auotnomyStoppedDeviated;
    const safetyErrorMessage = MultiRobotsStore.robots.get(serialNumber)?.safetyErrorMessage;
    const batteryLevel = MultiRobotsStore.robots.get(serialNumber)?.batteryLevel;
    const robotStateStamp = MultiRobotsStore.robots.get(serialNumber)?.robotStateStamp;
    const robotMotors = MultiRobotsStore.robots.get(serialNumber)?.motors;
    const oilTemperature = MultiRobotsStore.robots.get(serialNumber)?.oilTemperature;
    const [coolDownWarningColour, setCoolDownWarningColour] = useState('green');
    const [coolDownMessage, setCoolDownMessage] = useState('Temperatures Normal');
    const [isSuspendedRepeatingDialogOpen, setIsSuspendedRepeatingDialogOpen] = useState(false);
    const [subrowStateMessage, setSubrowStateMessage] = useState('');
    const [isSuspendedSubrowDifferent, setIsSuspendedSubrowDifferent] = useState(false);
    const [isSuspendedSubrowDifferentDialog, setIsSuspendedSubrowDifferentDialog] = useState(false);
    const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
    const [currentSplittedRowName, setCurrentSplittedRowName] = useState('');
    const [isNextPathInterRow, setIsNextPathInterRow] = useState(false);
    const [showInterRowConfirmationDialog, setshowInterRowConfirmationDialog] = useState(true);
    const [showRestartVideoDialog, setShowRestartVideoDialog] = useState(false);
    const [showRestartRobotDialog, setShowRestartRobotDialog] = useState(false);
    const [enableAutonmousBladesDialog, setEnableAutonmousBladesDialog] = useState(false);
    const [mainContainerStyleClass, setMainContainerStyleClass] = useState(classes.container);
    const [coordinates, setCoordinates] = useState(cords);
    const [solarSubRowsDataPoints, setSolarSubRowsDataPoints] = useState(solarSubRowsData);
    const [teleopsDrivingEnabled, setTeleopsDrivingEnabled] = useState(false);
    const [showBatteryAlert, setShowBatteryAlert] = useState(false);
    const [connectionStatusMessageColor, setConnectionStatusMessageColor] = useState({ color: '', message: '' });
    const [eStopType, setEstopType] = useState('');
    const isBladeRunning = MultiRobotsStore.robots.get(serialNumber)?.bladeRunning;
    const keepSelectedRobot = useRef(false);
    const containerRef = useRef(null);
    const spaceBarToPauseTimeout = useRef(null);
    const isSpaceBarLongPressed = useRef(null);
    const isSpaceBarPressed = useRef(null);
    const multiRobotContainerRef = useRef(null);
    const loadingDialogTimerInterval = useRef(null);
    const mqttClient = useRef(null);
    const userName = localStorage.getItem('username');
    const userEmail = localStorage.getItem('userEmail');
    const isSimMode = serialNumber?.includes('sim');
    const isSuspendedRepeating = !robotIsDoneRepeatingPath && start && STATUS_MAP[robotWpsState] === EXEC_SUSPENDED;
    const isDifferentSubrow = operatingSubrowId && !solarSubRows.some((row) => row.id === operatingSubrowId);
    const currentActiveRGName = MultiRobotsStore.robots.get(serialNumber)?.activeClient?.user;
    const hoseCoolingEnabled = MultiRobotsStore.robots.get(serialNumber)?.hoseCoolingEnabled;
    const isRobotLockedOut = MultiRobotsStore.robots.get(serialNumber)?.lockoutState !== '';
    const d = new Date();
    const currTime = d.getTime();
    let lastFixedRTKTime = null;
    const [isVideoStreamingRefreshed, setIsVideoStreamingRefreshed] = useState(false);

    const isAutonomyReadyParameters = {
      robotConnectionService,
      isDevMode,
      robotLat,
      robotLng,
      auotnomyStoppedDeviated,
      safetyEnabled,
      safetyErrorMessage,
      highLatency,
      latency,
      robotGpsFixStatus,
      robotStableLocalization,
      serialNumber,
      connectionError,
      hoseCoolingEnabled,
      staleTeleopsJoy
    };
    const isValidPositionParameters = {
      serialNumber,
      isDevMode,
      robotLat,
      robotLng,
      robotCurrentHeadingRad,
      auotnomyStoppedDeviated
    };
    const robotNotificationMessage = MultiRobotsStore.robots.get(serialNumber)?.notificationMessage || 'No New Notifications';
    const isAutonomyAndTeleopsDisabled =
      (MultiRobotsStore.teleOpsEnabled.status && serialNumber !== MultiRobotsStore.teleOpsEnabled.serialNumber) ||
      !isTeleopsStreamingEnabled ||
      robotSwEstopState ||
      robotEstopState ||
      userName !== currentActiveRGName ||
      robotSensingEdgeState;

    /** Sends repeat commands  */
    const sendRepeatCmdToRobot = async (action, useTeach = false) => {
      setUserRobot(true);
      const command = useTeach ? 'TEACH' : 'REPEAT';
      robotConnection?.current?.ros?.cmdRobotService(
        command,
        [`${action},${currentSubrow.current},${localStorage.getItem('username')},${selectedPathType}`],
        (result) => {
          if (!loadingDialogTimerInterval.current) {
            setLoading(false);
            setLoadingMessage('');
          }

          if (result.error_message === '') {
            if (action !== 'CANCEL' && action !== 'FINISH') {
              setIsStarted(true);
            } else {
              setIsStarted(false);
            }
            return;
          }
          setPause(false);
          setResume(true);
          switchControlMode(ROBOT_CONTROL_MODES.MANUAL, robotControlMode, robotConnection);
          applicationStore.pushError(
            'Error',
            `The robot ${serialNumber} encountered an error with this repeat action, please report this to the autonomy team if the issue persists`
          );
        }
      );
    };

    /** Handler for pausing autonomy operations */
    const pauseAutonomyOperation = async () => {
      if ((!start && (!resume || pause)) || STATUS_MAP[robotWpsState] === IN_USE) {
        sendRepeatCmdToRobot('PAUSE');
        // if (isBladeRunning) toggleAutonomousBlades();
        switchControlMode(ROBOT_CONTROL_MODES.MANUAL, robotControlMode, robotConnection);
        setResume(true);
        setPause(false);
      }
    };
    /** handles Sw estopping robot */
    const swEstopRobot = async () => {
      await robotConnection?.current?.ros?.cmdSwEstop(true);
      setSwEstopAllRobots(false);
    };

    /** sets Connection error to true and redenders message */
    const setMessageAndConnectionError = (message) => {
      setConnectionError(true);
      setErrorMessage(message);
      setTimeout(() => {
        setConnectionError(false);
      }, 7500);
    };

    const escFunction = (event) => {
      if (event.key === 'Escape') {
        changeDriveState('manual');
        sendRepeatCmdToRobot('PAUSE');
        resumeProcess();
      }
    };
    useEffect(() => {
      document.addEventListener('keydown', escFunction, false);
      return () => {
        document.removeEventListener('keydown', escFunction, false);
      };
    }, []);

    const stopProcess = () => {
      if (STATUS_MAP[robotWpsState] === IN_USE) {
        setShouldPauseAllRobots(true);
      }
    };

    const resumeProcess = () => {
      setShouldRobotPaused(false);
      setOpenRemoteGuardian(false);
      setRGOpened(false);
      setTeleopsDrivingEnabled(false);
      MultiRobotsStore.setTeleopsMode(serialNumber, false);
      changeDriveState('manual');
      if (isStarted) {
        pauseAutonomyOperation();
      }
    };
    /** Handler for changing robot driving state */
    const changeDriveState = useCallback(
      (nextControlMode) => {
        if (nextControlMode === 'teleops' && highLatency) {
          setMessageAndConnectionError(
            `Robot ${serialNumber} is experiencing a high video streaming latency of ${(latency * 1000).toFixed(2)} ms`
          );
          return;
        }
        if (nextControlMode === 'teleops' && staleTeleopsJoy) {
          setMessageAndConnectionError(
            `Robot ${serialNumber} is recieving stale Controller commands. Try re-connecting PS4 controller. Contact Autonomy if issue persists`
          );
          return;
        }
        if (currentSubrow.current && nextControlMode === 'autonomous') {
          const { isAutonomyReady, statusMessage } = isRobotAutonomyReady(isAutonomyReadyParameters, pauseAutonomyOperation);
          if (!isAutonomyReady) {
            setMessageAndConnectionError(statusMessage);
            return;
          }
          if (isStarted) {
            sendRepeatCmdToRobot('RESUME');
            setPause(true);
            setResume(false);
          }
        } else if (nextControlMode === 'manual' || nextControlMode === 'teleops') {
          if (nextControlMode === 'manual') {
            setTeleopsDrivingEnabled(false);
          }
          if (STATUS_MAP[robotWpsState] === IN_USE) {
            pauseAutonomyOperation();
          }
        }
        if (currentSubrow.current || (nextControlMode !== null && !(!isStarted && nextControlMode === 'autonomous'))) {
          robotConnection?.current?.ros.setControlMode(nextControlMode);
        }
      },
      [controlMode, isStarted]
    );
    /** Loading message on autonomy start */
    const startLoadingMsg = () => {
      if (isDevMode) {
        return;
      }
      setLoading(true);
      setLoadingMessage(`Provisioning ${formatStringForReadbility(serialNumber)}. Path will begin momentarily!`);
    };

    /**
     * Handler for Connection Error message
     * @param {} None
     * @returns {} None
     */
    const handleConnectionError = () => {
      if (isDevMode) {
        return;
      }
      setLoading(false);
      setLoadingMessage('');
      setMessageAndConnectionError(
        `An error occurred with robot ${robot.name}, check the robot's internet connection and if the issue occurs again, try to reboot the robot.`
      );
      setTeleopsDrivingEnabled(false);
      MultiRobotsStore.resetRobot(serialNumber);
      MultiRobotsStore.resetUiRobotWpsState(serialNumber);
      MultiRobotsStore.setTeleopsMode(serialNumber, false);
      pauseAutonomyOperation();
    };

    useEffect(() => {
      if (shouldRobotPaused) {
        pauseAutonomyOperation();
      }
      if (shouldPauseAllRobots) {
        pauseAutonomyOperation();
        setShouldPauseAllRobots(false);
      }
    }, [shouldRobotPaused, shouldPauseAllRobots]);

    useEffect(() => {
      setLoadingMessage(`${loadingCountdownMessage}${loadingCountdownTime} seconds`);
    }, [loadingCountdownTime]);

    useEffect(() => {
      if (swEstopAllRobots) {
        swEstopRobot();
      }
    }, [swEstopAllRobots]);

    const showLoadingDialogTimeout = (message, timeout) => {
      clearInterval(loadingDialogTimerInterval.current);
      setLoadingCountdownTime(timeout);
      setLoadingCountdownMessage(message);
      setLoading(true);

      // Starts an interval that decrements countdown every second
      loadingDialogTimerInterval.current = setInterval(() => {
        setLoadingCountdownTime((prevTime) => prevTime - 1);
      }, 1000);

      // Clear interval after countdown finishes
      setTimeout(() => {
        clearInterval(loadingDialogTimerInterval.current);
        loadingDialogTimerInterval.current = null;
        setLoading(false);
      }, timeout * 1000);
    };

    const handleRestartVideo = async (confirm = false) => {
      if (confirm) {
        changeDriveState('manual');
        await robotConnection?.current?.ros?.restartRobotService('video');
        setShowRestartVideoDialog(false);
        showLoadingDialogTimeout("Restarting the robot's video streaming system please wait ", 15);
      } else {
        setShowRestartVideoDialog(false);
      }
    };

    const handleRestartRobot = async (confirm = false) => {
      if (confirm) {
        await robotConnection?.current?.ros?.restartRobotService('robot');
        setShowRestartRobotDialog(false);
      } else {
        setShowRestartRobotDialog(false);
      }
    };

    const handleSolarRowsStatus = async (updatedStatus) => {
      const updatedSubrows = solarSubRows.map((subrow) => {
        if (subrow.value.includes(selectedSolarSubRows[0])) {
          return { ...subrow, status: updatedStatus };
        }
        return subrow;
      });
      setSolarSubRows(updatedSubrows);
      const updatedSubrow = updatedSubrows.find((subrow) => subrow.value.includes(selectedSolarSubRows[0]));
      if (updatedSubrow) {
        await updateSubrow(updatedSubrow.id, { status: updatedStatus });
      } else if (operatingSubrowId !== -1) {
        await updateSubrow(operatingSubrowId, { status: updatedStatus });
        const updatedSubrows = solarSubRows.map((subrow) => {
          if (subrow.id === operatingSubrowId) {
            return { ...subrow, status: updatedStatus };
          }
          return subrow;
        });
        setSolarSubRows(updatedSubrows);
      }
    };

    const handleReleaseRobot = async (cancelButtonPressed = true) => {
      if (STATUS_MAP[robotWpsState] !== AVAILABLE || cancelButtonPressed) {
        setIsStarted(false);
        setStart(true);
        setIsSuspendedRepeatingDialogOpen(false);
        await sendRepeatCmdToRobot('CANCEL');
        await handleSolarRowsStatus(NOT_STARTED);
        if (selectedSolarSubRows.length > 0) {
          MultiRobotsStore.setSelectedSolarSubRows(serialNumber, selectedSolarSubRows.slice(1));
        }
      }
      switchControlMode(ROBOT_CONTROL_MODES.MANUAL, robotControlMode, robotConnection);
      setPause(false);
    };

    const cancelRepeatingTask = async (done = false) => {
      setIsStarted(false);
      switchControlMode(ROBOT_CONTROL_MODES.MANUAL, robotControlMode, robotConnection);
      if (!done) {
        handleReleaseRobot(true);
      } else {
        await sendRepeatCmdToRobot('FINISH');
      }
      if (!start) {
        setIsStarted(false);
        setStart(true);
        if (pause) {
          setPause(false);
        } else {
          setPause(true);
        }
      }
      MultiRobotsStore.updateDoneRepeatingMission(serialNumber, false);
      MultiRobotsStore.updateDoneRepeatingPath(serialNumber, false);
      setResetRobot(true);
    };

    const changeRepeatingState = () => {
      robotConnectionService?.current?.ros.getUpdatedDynamicConfig((updatedDynamicParameters) => {
        if (updatedDynamicParameters !== null) {
          MultiRobotsStore.updateDynamicConfigParameters(serialNumber, updatedDynamicParameters);
        }
      });
      const { isAutonomyReady, statusMessage } = isRobotAutonomyReady(isAutonomyReadyParameters, pauseAutonomyOperation);
      if (!isAutonomyReady) {
        setMessageAndConnectionError(statusMessage);
        return;
      }
      if (STATUS_MAP[robotWpsState] === EXEC_SUSPENDED) {
        setPause(!pause);
        setResume(!resume);
        switchControlMode(ROBOT_CONTROL_MODES.AUTONOMOUS, robotControlMode, robotConnection);
        sendRepeatCmdToRobot('RESUME');
      } else if (STATUS_MAP[robotWpsState] === IN_USE) {
        pauseAutonomyOperation();
      } else if ((start || STATUS_MAP[robotWpsState] === AVAILABLE) && selectedSolarSubRows.length > 0) {
        currentSubrow.current = selectedSolarSubRows[0];
        const subrowReferencePoint = {
          lat: solarSubRowsDataPoints[currentSubrow.current][0].lat,
          lng: solarSubRowsDataPoints[currentSubrow.current][0].long,
          angle: solarSubRowsDataPoints[currentSubrow.current][0].angle
        };
        const { isValidPosition, message } = isValidRobotPosition(
          isValidPositionParameters,
          subrowReferencePoint,
          setMessageAndConnectionError
        );
        if (!isValidPosition) {
          setMessageAndConnectionError(message);
        } else {
          setIsStarted(true);
          setStart(false);
          setPause(!pause);
          switchControlMode(ROBOT_CONTROL_MODES.AUTONOMOUS, robotControlMode, robotConnection);
          setResetRobot(true);
          if (STATUS_MAP[robotWpsState] === AVAILABLE || userRobot) {
            sendRepeatCmdToRobot('SUBROWS_START');
            startLoadingMsg();
          } else {
            setMessageAndConnectionError(`Robot ${serialNumber} is already in-use, check it again again later or select another robot!`);
            cancelRepeatingTask();
            pauseAutonomyOperation();
          }
        }
      }
    };

    const handleSuspendedRepeatingDialogGoBack = async () => {
      if (operatingSubrowId === -1) {
        return;
      }
      const subrowInfo = await getSubrowMetaData(operatingSubrowId);
      const formattedSubrow = formatSubrows([subrowInfo], selectedRegionId, selectedPropertyId, selectedBlockId, selectedSubBlockId)[0];
      const splittedRow = formattedSubrow.name.split('/').at(-1);
      setCurrentSplittedRowName(splittedRow);
      const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
      formattedSubrow.name = splittedRowName;
      formattedSubrow.value = splittedRow;
      MultiRobotsStore.setSelectedSolarSubRows(serialNumber, [formattedSubrow.value]);
      currentSubrow.current = selectedSolarSubRows[0];
      setIsSuspendedRepeatingDialogOpen(false);
      setResume(true);
      setStart(false);
      setIsStarted(true);
      // Call the last wps_index service
      robotConnection?.current?.ros?.getLastWpsIndexService((wpsInexStr) => {
        setCurrentPositionIndex(parseInt(wpsInexStr));
      });
    };
    /** Fetch solar subrows and renders them on map */
    const fetchAndSetMapData = async () => {
      try {
        const result = await getSolarMapData({
          regionId: selectedRegionId,
          propertyId: selectedPropertyId,
          blockId: selectedBlockId,
          subblockId: selectedSubBlockId,
          pathType: selectedPathType
        });
        setSolarSubRowsDataPoints(result);
        const coordinatePoints = Object.entries(result).map(([key, points]) =>
          points.map((sample) => ({ key, lat: Number(sample.lat), lng: Number(sample.long), angle: Number(sample.angle) }))
        );
        setCoordinates(coordinatePoints);
      } catch (e) {
        console.error(`Error ${e}`);
      }
    };

    // Handles input from Logitech Extreme 3D and PS4 controllers
    const handleJoystickState = (currentState) => {
      const ros = robotConnection?.current?.ros;
      if (!ros) {
        return;
      }
      let gamepads;

      if (navigator.getGamepads) {
        gamepads = navigator.getGamepads();
      } else if (navigator.webkitGetGamepads) {
        gamepads = navigator.webkitGetGamepads();
      } else {
        gamepads = [];
      }
      gamepads.forEach((gamepad) => {
        const gp = gamepad;
        // If there are any gamepads connected
        if (gp && robotControlMode === 'teleops') {
          // PS4
          if (gp.id.includes('Wireless Controller')) {
            PS4_PARAMETERS.gamepadIndex = gp.index;
            const buttons = Array(16).fill(0);
            const axes = Array(8).fill(0.0);
            // PERIPHERALS
            buttons[0] = gp.buttons[2].value;
            buttons[1] = gp.buttons[0].value;
            buttons[2] = gp.buttons[1].value;
            buttons[3] = gp.buttons[3].value;
            buttons[4] = gp.buttons[4].value;
            buttons[5] = gp.buttons[5].value;
            buttons[8] = gp.buttons[8].value;
            buttons[9] = gp.buttons[9].value;
            buttons[10] = gp.buttons[10].value;
            buttons[11] = gp.buttons[11].value;
            buttons[12] = gp.buttons[16].value;
            // DRIVING
            if (teleopsDrivingEnabled) {
              if (gp.buttons[6].value > PS4_PARAMETERS.analogDeadZone) buttons[6] = 1;
              if (gp.buttons[7].value > PS4_PARAMETERS.analogDeadZone) buttons[7] = 1;
              axes[0] = Math.abs(gp.axes[0]) > PS4_PARAMETERS.analogDeadZone ? gp.axes[0] * -1.0 : axes[0];
              axes[1] = Math.abs(gp.axes[1]) > PS4_PARAMETERS.analogDeadZone ? gp.axes[1] * -1.0 : axes[1];
              axes[2] = Math.abs(gp.axes[2]) > PS4_PARAMETERS.analogDeadZone ? gp.axes[2] * -1.0 : axes[2];
              axes[5] = Math.abs(gp.axes[3]) > PS4_PARAMETERS.analogDeadZone ? gp.axes[3] * -1.0 : axes[5];
              if (gp.buttons[15].value) axes[6] = -1.0;
              if (gp.buttons[14].value) axes[6] = 1.0;
              if (gp.buttons[12].value) axes[7] = 1.0;
              if (gp.buttons[13].value) axes[7] = -1.0;
            }
            robotConnection?.current?.ros.publishJoy(buttons, axes, robotStateStamp);
          }
        }
      });
    };

    /** Fecthes solar rows and stores them in solarSubRow state variable */
    const fetchAndSetSolarSubRows = async () => {
      const subRows = await fetchSubBlockSubRows(
        selectedSubBlockId,
        selectedPathType,
        selectedRegionId,
        selectedPropertyId,
        selectedBlockId,
        setSolarSubRows
      );
      setSolarSubRows(subRows);
    };

    useEffect(() => {
      if (selectedSubBlockId) {
        fetchAndSetSolarSubRows();
      }
      if (selectedBlockId && selectedSubBlockId && selectedPathType) {
        fetchAndSetMapData();
      }
    }, [selectedBlockId, selectedSubBlockId, selectedPathType]);

    useEffect(() => {
      if (robotNotificationMessage.includes('stuck')) {
        if (robotNotificationMessage.includes('stalled')) {
          setMessageAndConnectionError(`Robot ${serialNumber}: Autonomy is stuck, MOTORS ARE STALLED!`);
        } else {
          setMessageAndConnectionError(`Robot ${serialNumber}: Autonomy is stuck, WHEELS ARE SLIPPING !`);
        }
      }
      if (!isDevMode && (!safetyEnabled || highLatency || auotnomyStoppedDeviated || staleTeleopsJoy)) {
        if (auotnomyStoppedDeviated && robotControlMode === 'autonomous') {
          setMessageAndConnectionError(
            `Robot ${serialNumber} is experiencing a high autonomy path deviation, please drive it manually back to the path!`
          );
        } else if (!safetyEnabled) {
          let errMessage = `Robot ${serialNumber}'s safety system is down because of [ ${safetyErrorMessage} ].`;
          if (safetyErrorMessage.includes('motors')) {
            errMessage += ' Please sw-estop then unestop to re-enable it!';
          }
          if (safetyErrorMessage.includes('tank') || safetyErrorMessage.includes('base')) {
            return;
          }
          setMessageAndConnectionError(errMessage);
        } else if (highLatency) {
          setMessageAndConnectionError(
            `Robot ${serialNumber} is experiencing a high video streaming latency of ${(latency * 1000).toFixed(2)} ms`
          );
        } else if (staleTeleopsJoy) {
          robotConnection?.current?.ros.setControlMode('manual');
          setMessageAndConnectionError(
            `Robot ${serialNumber} is recieving stale Controller commands. Try re-connecting PS4 controller. Contact Autonomy if issue persists`
          );
        } else {
          // no errors happened, just a controlMode changed
          return;
        }
        if (robotControlMode === 'autonomous') {
          switchControlMode(ROBOT_CONTROL_MODES.MANUAL, robotControlMode, robotConnection);
        }
        pauseAutonomyOperation();
      }
    }, [safetyEnabled, highLatency, robotControlMode, auotnomyStoppedDeviated, staleTeleopsJoy]);

    useEffect(() => {
      if (resetRobot) {
        MultiRobotsStore.resetRobot(serialNumber);
        setResetRobot(false);
      }
    }, [resetRobot]);

    useEffect(() => {
      if (operatingSubrowName) {
        if (selectedSolarSubRows.length > 0) {
          MultiRobotsStore.setSelectedSolarSubRows(serialNumber, selectedSolarSubRows.slice(1));
        }
        const subRowFileName = `${operatingSubrowName}.csv`;
        MultiRobotsStore.setSelectedSolarSubRows(serialNumber, [subRowFileName]);
        robotConnectionService?.current?.ros.getUpdatedDynamicConfig((updatedDynamicParameters) => {
          if (updatedDynamicParameters !== null) {
            MultiRobotsStore.updateDynamicConfigParameters(serialNumber, updatedDynamicParameters);
          }
        });
      }
    }, [operatingSubrowName]);

    useEffect(() => {
      if (robotConnectionService.current !== null && robotPreviousWpsState !== robotWpsState) {
        // check for idle robot state data
        if ((Date.now() - robotLastRobotStateReceived) / 1000 > 20 && robotControlMode === 'autonomous') {
          // in seconds
          if (pause && !isDevMode) {
            setMessageAndConnectionError(
              `Robot State Error: Unable to continue executing the path. ${serialNumber}'s current state is not being communicated.`
            );
            setResume(!resume);
            pauseAutonomyOperation();
          }
        }
        // Check for change in other Waypoints state
        if (!start && robotIsDoneRepeatingMission) {
          setIsStarted(false);
          setStart(true);
          handleSolarRowsStatus(COMPLETED);
          cancelRepeatingTask(true);
          if (!isNextPathInterRow) {
            MultiRobotsStore.setSelectedSolarSubRows(serialNumber, selectedSolarSubRows.slice(1));
          }
        } else if (STATUS_MAP[robotWpsState] === AVAILABLE) {
          setIsStarted(false);
          setStart(true);
          setPause(false);
          setResume(false);
        } else if (!start && STATUS_MAP[robotWpsState] === IN_USE) {
          setIsStarted(true);
          setStart(false);
          setPause(true);
          MultiRobotsStore.setTeleopsMode(serialNumber, false);
          handleSolarRowsStatus(IN_PROGRESS);
        } else if (STATUS_MAP[robotWpsState] === EXEC_SUSPENDED) {
          setResume(true);
          setPause(false);
        }
        MultiRobotsStore.updatePreviousWpsState(serialNumber, robotPreviousWpsState);
      }
    }, [robotConnectionService, start, robotWpsState, robotIsDoneRepeatingMission]);

    useEffect(() => {
      if (lastFixedRTKTime !== null && robotGpsFixStatus.includes('Fixed')) {
        lastFixedRTKTime = null;
      }
      if (lastFixedRTKTime === null && !robotGpsFixStatus.includes('Fixed')) {
        lastFixedRTKTime = d.getTime();
      }
      if (lastFixedRTKTime && currTime - lastFixedRTKTime > ERROR_TIME_THRESHOLD && robotControlMode !== 'teleops' && !isDevMode) {
        setMessageAndConnectionError(
          `RTK Error: Can't continue repeating this subrow autonomously, the robot ${serialNumber} does not have an RTK GPS data !`
        );
        pauseAutonomyOperation();
      }
    }, [robotGpsFixStatus]);

    useEffect(
      () =>
        autorun(() => {
          if (robotControlMode !== robotPreviousControlMode) {
            if (isStarted && STATUS_MAP[robotWpsState] !== AVAILABLE) {
              if (robotControlMode === 'autonomous' && robotPreviousControlMode !== 'autonomous' && !pause) {
                setPause(true);
                sendRepeatCmdToRobot('RESUME');
              } else if (robotControlMode !== 'autonomous' && robotPreviousControlMode !== robotControlMode && pause) {
                setPause(false);
                sendRepeatCmdToRobot('PAUSE');
              }
            }
            setControlMode(robotControlMode);
          }
        }),
      [start, pause]
    );

    useEffect(() => {
      if (robotControlMode !== 'teleops') {
        setTeleopsDrivingEnabled(false);
      }
      if (robotControlMode !== 'autonomous' && STATUS_MAP[robotWpsState] === IN_USE) {
        pauseAutonomyOperation();
      }
    }, [robotControlMode]);

    useEffect(() => {
      if (isSuspendedRepeating && operatingSubrowId !== -1) {
        if (subrowStateMessage !== '') {
          setIsSuspendedSubrowDifferent(isDifferentSubrow);
          setIsSuspendedSubrowDifferentDialog(isDifferentSubrow);
        }
        setIsSuspendedRepeatingDialogOpen(!isDifferentSubrow);
        keepSelectedRobot.current = true;
      }
    }, [robotWpsState, robotConnectionService.current?.ros?.ros?.isConnected, subrowStateMessage]);

    useEffect(() => {
      const handleKeyDown = (e) => {
        if (e.code === 'Space' || e.key === ' ') {
          if (!isSpaceBarPressed.current) {
            isSpaceBarPressed.current = true;

            // Start a timeout to detect long press space bar
            // Trigger pause all robots if space key pressed for > 2s
            spaceBarToPauseTimeout.current = setTimeout(() => {
              e.preventDefault();
              e.stopPropagation();
              setShouldPauseAllRobots(true);

              // reset space bar pressed
              // set long press to true
              isSpaceBarPressed.current = false;
              isSpaceBarLongPressed.current = true;
              clearTimeout(spaceBarToPauseTimeout.current);
            }, 2000);
          }
        }
      };

      const handleKeyUp = (e) => {
        if ((e.code === 'Space' || e.key === ' ') && isSpaceBarPressed.current) {
          clearTimeout(spaceBarToPauseTimeout.current);
          // trigger SW estop on key release if key has not been pressed for 2 or more seconds
          if (!isSpaceBarLongPressed.current) {
            e.preventDefault();
            e.stopPropagation();
            setSwEstopAllRobots(true);
            const [topic, payload] = buildTopicAndPayloadForMqttEstop(true, userEmail, serialNumber);
            mqttClient.current.publish(topic, payload);
          }
          isSpaceBarPressed.current = false;
          isSpaceBarLongPressed.current = false;
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keyup', handleKeyUp);
      };
    }, [robotWpsState]);

    // /** Closes battery warning dialogue when user confirms/agrees to take the robot to a charging station */
    const handleLowBatteryConfirmation = () => {
      if (!userConfirmedBatteryWarning && showBatteryAlert) {
        setShowBatteryAlert(false);
        setUserConfirmedBatteryWarning(true);
      }
    };

    /**
     * Disconnects the robot from Remote guardian.
     */
    const handleRobotDisconnect = async () => {
      if (robotConnectionService.current !== null) {
        try {
          await pauseAutonomyOperation();
          setShowBatteryAlert(false);
          MultiRobotsStore.removeRobot(serialNumber);
          applicationStore.pushError(
            'Error Critical Battery Level',
            `${serialNumber} batteries are crticaly low. Swap batteries or initiate remote shutdown`
          );
        } catch (e) {
          console.error(e);
        }
      }
    };

    /**
     * This hook monitors battery levels and handles low or critical levels empty
     */
    useEffect(() => {
      // handler for low battery levels (1% - 10%)
      const handleLowBattery = async () => {
        if (
          batteryLevel !== null &&
          !isSimMode &&
          batteryLevel <= BATTERIES_WARNING_LEVELS.LOW_LEVEL &&
          batteryLevel > BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL &&
          !userConfirmedBatteryWarning
        ) {
          await pauseAutonomyOperation();
          setShowBatteryAlert(true);
        }
      };
      // handler for low battery levels (0% - 1%)
      const handleCriticalBattery = () => {
        if (
          batteryLevel !== null &&
          !isSimMode &&
          batteryLevel < BATTERIES_WARNING_LEVELS.LOW_LEVEL &&
          batteryLevel <= BATTERIES_WARNING_LEVELS.CRITICAL_LEVEL &&
          batteryLevel > 0
        ) {
          setShowBatteryAlert(true);
        }
      };
      handleLowBattery();
      handleCriticalBattery();
    }, [batteryLevel]);

    useEffect(() => {
      if (robotIsDoneRepeatingPath) {
        handleSolarRowsStatus(COMPLETED);
      }
      if (isNextPathInterRow && robotIsDoneRepeatingPath) {
        setshowInterRowConfirmationDialog(true);
      } else {
        setshowInterRowConfirmationDialog(false);
      }
    }, [robotConnectionService.current?.ros?.ros?.isConnected, isNextPathInterRow, robotIsDoneRepeatingPath]);

    /**
     * Handles sending confirmation or cancellation for inter-row path from RG to robot.
     * @param {Boolean} confirmInterRow user confirmation or cancellation
     * @returns {} None
     */
    const handleConfirmStartInterRowPath = async (confirmInterRow) => {
      if (!confirmInterRow) {
        // When Cancel inter-row hopping cancelled
        cancelRepeatingTask(true);
      }
      if (robotConnection?.current && robotConnectionService.current?.ros?.ros?.isConnected) {
        await robotConnectionService?.current?.ros.startInterRowCmdService({ data: confirmInterRow }, (result) => {
          if (!result.success) {
            applicationStore.pushError(
              'Error Starting Inter-Row Path',
              `${serialNumber} has encountered an error starting Inter-Row path. Please contact Autonomy.`
            );
            setIsNextPathInterRow(false);
          }
        });
      }
      setIsNextPathInterRow(false);
    };

    /** Handles entering full screen mode on multi-rg */
    const handleFullScreen = () => {
      setShouldPauseAllRobots(true);
      setOpenRemoteGuardian(true);
      MultiRobotsStore.setTeleopsMode(serialNumber, false);
      stopProcess();
      setRGOpened(true);
    };

    /** Handle Robot connection and various topic subscriptions */
    useRobotConnectionService(
      serialNumber,
      userName,
      isMountedRef,
      robotConnectionService,
      robotConnection,
      classes,
      MultiRobotsStore,
      applicationStore,
      handleConnectionError,
      setMainContainerStyleClass,
      setConnectionError,
      setIsNextPathInterRow,
      showInterRowConfirmationDialog,
      setshowInterRowConfirmationDialog,
      setConnectionStatusMessageColor,
      setSelectedRegionId,
      setSelectedPropertyId,
      setSelectedBlockId,
      setSelectedSubBlockId,
      setSelectedPathType
    );

    /** Handles toggling autonomous blades on and off */
    const toggleAutonomousBlades = async () => {
      const axes = Array(8).fill(0.0);
      if (!isBladeRunning && STATUS_MAP[robotWpsState] === IN_USE) {
        await robotConnection?.current?.ros.publishJoy(TOGGLE_AUTONOMOUS_BLADES, axes, robotStateStamp);
        setEnableAutonmousBladesDialog(false);
      }
      if (isBladeRunning) {
        await robotConnection?.current?.ros.publishJoy(TOGGLE_AUTONOMOUS_BLADES, axes, robotStateStamp);
        setEnableAutonmousBladesDialog(false);
      }
    };

    const setAutonomousBladeState = async (autonomousBladeState) => {
      try {
        await robotConnectionService?.current?.ros.setAutonomousBladeState({ data: autonomousBladeState }, (result) => {
          if (!result.success) {
            applicationStore.pushError(
              'Unable to set autonomous blades',
              `${serialNumber} has encountered an issue with setting autonomous blade mode. Please contact Service.`
            );
          }
        });
      } catch (error) {
        applicationStore.pushError('Error!', 'Encountered an error with this request. Please contact the Autonomy team.');
      }
    };

    const handlesetTeleopsDrivingEnabled = (value) => {
      setTeleopsDrivingEnabled(value);
    };

    useEffect(() => {
      const showWrongOperatingSubrowMessage = async () => {
        try {
          const message = await verifySelectedSubrow(operatingSubrowId, blocksStore, subBlocksStore, chaperonePropertyStore, regionsStore);
          setSubrowStateMessage(message);
        } catch (error) {
          console.error('Error fetching subrow message:', error);
        }
      };
      showWrongOperatingSubrowMessage();
    }, []);

    useEffect(() => {
      if (robotEstopState) {
        setEstopType(ESTOP_TYPES.PHYSICAL_ESTOP);
      }
      if (robotSwEstopState) {
        setEstopType(ESTOP_TYPES.SW_ESTOP);
      }
      if (isRobotLockedOut) {
        setEstopType(ESTOP_TYPES.CONTROL_LOCKOUT);
      }
      if (robotSensingEdgeState) {
        setEstopType(ESTOP_TYPES.SENSING_EDGES);
      }
      if (robotSafetyStopState === 1) {
        setEstopType(ESTOP_TYPES.SAFETY_STOP);
      }
    }, [robotEstopState, robotSwEstopState, isRobotLockedOut, robotSensingEdgeState, robotSafetyStopState]);

    /** handles pausing the robot when it's in autonomous mode
     * but active rg or current user is null.
     */
    useEffect(() => {
      const handlePauseRobot = async () => {
        if (robotWpsState !== -1 && (!currentActiveRGName || currentActiveRGName === '' || userName !== currentActiveRGName)) {
          await sendRepeatCmdToRobot('Pause');
        }
      };

      handlePauseRobot();
    }, [currentActiveRGName]);

    /**
     * handles creating an mqtt client and establishing connection with iot broker
     * Ref variable will be removed when component dismounts
     */
    useEffect(() => {
      (async () => {
        mqttClient.current = new MqttClient(
          (robotSerialNumber, iotStatus, user, currentSubsection, robotState = {}, statusTimestamp = null) => {
            const robotHasWssConnection = robotConnection?.current?.ros?.ros?.isConnected;
            if (robotSerialNumber === serialNumber && !robotHasWssConnection) {
              const isSwEstopEnagaged = robotState?.safety_state?.sw_estop_is_on;
              MultiRobotsStore.updateMqttSwEstopState(serialNumber, isSwEstopEnagaged);
            }
          }
        );
        await mqttClient.current.connect();
      })();
      return () => {
        if (mqttClient.current) {
          mqttClient.current.disconnect();
        }
      };
    }, [robotConnection.current]);

    useEffect(() => {
      if (oilTemperature) {
        if (oilTemperature < 70 && oilTemperature >= 60) {
          setCoolDownWarningColour('#c29b02');
          setCoolDownMessage('Warning, Elevated Temperatures');
        }
        if (oilTemperature >= 70) {
          setCoolDownWarningColour('#bf140b');
          setCoolDownMessage('Temperatures Critical. Cool Down Robot');
        }
      }
      if (robotMotors) {
        const motorTemperatures = [
          robotMotors.get('LEFT')?.motor_temp,
          robotMotors.get('RIGHT')?.motor_temp,
          robotMotors.get('HYDRAULIC')?.motor_temp
        ];
        const motorControllerTemp = [
          robotMotors.get('LEFT')?.mc_temp,
          robotMotors.get('RIGHT')?.mc_temp,
          robotMotors.get('HYDRAULIC')?.mc_temp
        ];
        if (motorTemperatures.some((temp) => temp >= 90 && temp < 105)) {
          setCoolDownWarningColour('#c29b02');
          setCoolDownMessage('Warning, Elevated Temperatures');
        } else if (motorTemperatures.some((temp) => temp > 105)) {
          setCoolDownWarningColour('#bf140b');
          setCoolDownMessage('Temperatures Critical. Cool Down Robot');
        }
        if (motorControllerTemp.some((temp) => temp >= 70 && temp < 80)) {
          setCoolDownWarningColour('#c29b02');
          setCoolDownMessage('Warning, Elevated Temperatures');
        } else if (motorControllerTemp.some((temp) => temp >= 80)) {
          setCoolDownWarningColour('#bf140b');
          setCoolDownMessage('Temperatures Critical. Cool Down Robot');
        }
        if (motorControllerTemp.every((temp) => temp < 70) && motorTemperatures.every((temp) => temp < 90) && oilTemperature < 0) {
          setCoolDownWarningColour('green');
          setCoolDownMessage('Temperatures Normal');
        }
      }
    }, [oilTemperature, robotMotors]);

    return (
      <>
        <div id={serialNumber} className={mainContainerStyleClass} ref={containerRef}>
          <ConfirmActionDialog
            title="Begin Inter-Row Hopping"
            open={showInterRowConfirmationDialog}
            containerRef={!openRemoteGuardian ? containerRef : multiRobotContainerRef}
            key={`${serialNumber}InterRowPrompt`}
            action="Blades-ON"
            action2="Blades-OFF"
            body={`${serialNumber} has completed the current row. Go to the next row with running blades or without?`}
            handleClose={() => {
              handleConfirmStartInterRowPath(false);
            }}
            handleAction={() => {
              handleConfirmStartInterRowPath(true);
              setAutonomousBladeState(true);
            }}
            handleAction2={() => {
              handleConfirmStartInterRowPath(true);
              setAutonomousBladeState(false);
            }}
            backdropProps={{ style: { position: 'absolute' } }}
          />
          <ConfirmActionDialog
            title="Enable Autonomous Blades"
            open={enableAutonmousBladesDialog}
            containerRef={!openRemoteGuardian ? containerRef : multiRobotContainerRef}
            key={`${serialNumber}AutonomousBladesPrompt`}
            action="Confirm"
            body={`Enable Autonmous Blades on ${serialNumber}?`}
            handleClose={() => {
              setEnableAutonmousBladesDialog(false);
            }}
            handleAction={() => {
              toggleAutonomousBlades();
            }}
            backdropProps={{ style: { position: 'absolute' } }}
          />
          <ConfirmActionDialog
            title="Restart Video Streaming?"
            open={showRestartVideoDialog}
            containerRef={!openRemoteGuardian ? containerRef : multiRobotContainerRef}
            key={`${serialNumber}RestartVideoPrompt`}
            action="Confirm"
            body={`Please Confirm to continue restarting video streaming on ${formatStringForReadbility(serialNumber)}`}
            handleClose={() => {
              handleRestartVideo(false);
            }}
            handleAction={() => {
              handleRestartVideo(true);
            }}
          />
          <ConfirmActionDialog
            title="Restart Robot's Software?"
            open={showRestartRobotDialog}
            containerRef={!openRemoteGuardian ? containerRef : multiRobotContainerRef}
            key={`${serialNumber}RestartRobotPrompt`}
            action="Confirm"
            actionDisabled={!isAutonomyAndTeleopsDisabled}
            body={`Please Confirm to continue restarting ${formatStringForReadbility(serialNumber)}. Robot needs to be Estopped.`}
            handleClose={() => {
              handleRestartRobot(false);
            }}
            handleAction={() => {
              handleRestartRobot(true);
            }}
          />
          <BatteryAlert
            robotSerial={serialNumber}
            openParam={showBatteryAlert}
            onConfirm={handleLowBatteryConfirmation}
            batteryLevel={batteryLevel}
            onDisconnect={handleRobotDisconnect}
            containerRef={!openRemoteGuardian ? containerRef : multiRobotContainerRef}
          />
          <ConnectionErrorDialog
            backdropProps={{ style: { position: 'absolute' } }}
            style={{ position: 'absolute' }}
            open={connectionError}
            handleClose={() => setConnectionError(false)}
            errorMessage={errorMessage}
            containerRef={!openRemoteGuardian ? containerRef : multiRobotContainerRef}
          />
          <LoadingDialog
            style={{ position: 'absolute' }}
            backdropProps={{ style: { position: 'relative' } }}
            containerRef={!openRemoteGuardian ? containerRef : multiRobotContainerRef}
            show={loading}
            message={loadingMessage}
          />
          <div className={classes.controlPanel}>
            <ControlPanel
              solarSubRows={solarSubRows}
              serialNumber={serialNumber}
              robot={robot}
              robotLat={robotLat}
              robotLng={robotLng}
              robotHeading={robotCurrentHeadingRad}
              cords={coordinates}
              isRightOffset={isRightOffset}
              selectedProperty={selectedProperty}
              selectedBlockId={selectedBlockId}
              setSelectedBlockId={setSelectedBlockId}
              selectedSubBlockId={selectedSubBlockId}
              setSelectedSubBlockId={setSelectedSubBlockId}
              selectedPathType={selectedPathType}
              setSelectedPathType={setSelectedPathType}
              robotWpsState={robotWpsState}
              cancelRepeatingTask={cancelRepeatingTask}
              resetRobot={resetRobot}
              containerRef={containerRef}
              updateSolarSubRows={async () => fetchAndSetSolarSubRows()}
              robotGpsState={robotGpsFixStatus}
              robotInsState={robotStableLocalization}
              robotInsStatus={robotInsStatus}
              coolDownMessage={coolDownMessage}
              coolDownWarningColour={coolDownWarningColour}
            />
          </div>
          <Box className={classes.controls}>
            <MultiRobotsViewControls handleFullScreen={handleFullScreen} serialNumber={serialNumber} />
            <AutonomyControlButtons
              selectedSolarSubRows={selectedSolarSubRows}
              isAutonomyAndTeleopsDisabled={isAutonomyAndTeleopsDisabled}
              isSuspendedSubrowDifferent={isSuspendedSubrowDifferent && STATUS_MAP[robotWpsState] === IN_USE}
              changeRepeatingState={changeRepeatingState}
              setShouldPauseAllRobots={setShouldPauseAllRobots}
              setEnableAutonmousBladesDialog={setEnableAutonmousBladesDialog}
              toggleAutonomousBlades={toggleAutonomousBlades}
              robotConnection={robotConnection}
              handlesetTeleopsDrivingEnabled={handlesetTeleopsDrivingEnabled}
              teleopsDrivingEnabled={teleopsDrivingEnabled}
              serialNumber={serialNumber}
              changeDriveState={changeDriveState}
              handleJoystickState={handleJoystickState}
              setShowRestartVideoDialog={setShowRestartVideoDialog}
              setShowRestartRobotDialog={setShowRestartRobotDialog}
              setIsVideoStreamingRefreshed={setIsVideoStreamingRefreshed}
              isVideoStreamingRefreshed={isVideoStreamingRefreshed}
              userName={userName}
              userEmail={userEmail}
              mqttClient={mqttClient}
            />
          </Box>
          <div className={classes.videoArea}>
            {!RGOpened ? <VideoStreamMultiPageView key={isVideoStreamingRefreshed} serialNumber={serialNumber} hide={RGOpened} /> : null}
            {!isDevMode && (
              <EStopIndicator
                eStopEngaged={robotEstopState || robotSwEstopState || robotSensingEdgeState}
                videoStream
                isMultiRobots
                eStopType={eStopType}
              />
            )}
          </div>
          <div className={classes.notification}>
            <Box className={classes.notificationBox}>
              <Typography className={classes.customNotificationText}>{robotNotificationMessage}</Typography>
            </Box>
          </div>
        </div>
        {openRemoteGuardian ? (
          <Dialog fullScreen open={openRemoteGuardian} fullWidth ref={multiRobotContainerRef}>
            <DialogTitle>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  resumeProcess();
                }}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <MultiRobotsRemoteGuardianRepeat
                key={`${serialNumber}SingleViewContainer`}
                containerRef={multiRobotContainerRef}
                serialNumber={serialNumber}
                changeDriveState={changeDriveState}
                changeRepeatingState={changeRepeatingState}
                cancelRepeatingTask={cancelRepeatingTask}
                setIsStarted={setIsStarted}
                solarSubRows={solarSubRows}
                isAutonomyAndTeleopsDisabled={isAutonomyAndTeleopsDisabled}
                connectionError={connectionError}
                handleCloseErrorDialog={() => setConnectionError(false)}
                errorMessage={errorMessage}
                robotConnection={robotConnection}
                solarSubRowsData={solarSubRowsDataPoints}
                currentPositionIndex={currentPositionIndex}
                currentSplittedRowName={currentSplittedRowName}
                cords={coordinates}
                resetRobot={resetRobot}
                robotConnectionService={robotConnectionService}
                openRemoteGuardian={openRemoteGuardian}
                controlViewLoading={loading}
                controlViewLoadingMessage={loadingMessage}
                handleRestartVideo={handleRestartVideo}
                isRightOffset={isRightOffset}
                isTeleopsStreamingEnabled={isTeleopsStreamingEnabled}
                selectedBlockId={selectedBlockId}
                selectedSubBlockId={selectedSubBlockId}
                selectedPathType={selectedPathType}
                teleopsDrivingEnabled={teleopsDrivingEnabled}
                setTeleopsDrivingEnabled={setTeleopsDrivingEnabled}
                setEnableAutonmousBladesDialog={setEnableAutonmousBladesDialog}
                toggleAutonomousBlades={toggleAutonomousBlades}
                isBladeRunning={isBladeRunning}
                handleJoystickState={handleJoystickState}
                connectionStatusMessageColor={connectionStatusMessageColor}
                setShowRestartRobotDialog={setShowRestartRobotDialog}
                setShowRestartVideoDialog={setShowRestartVideoDialog}
                robotSensingEdgeState={robotSensingEdgeState}
                isVideoStreamingRefreshed={isVideoStreamingRefreshed}
                setIsVideoStreamingRefreshed={setIsVideoStreamingRefreshed}
                updateSolarSubRows={async () => fetchAndSetSolarSubRows()}
                robotGpsState={robotGpsFixStatus}
                robotInsState={robotStableLocalization}
                robotInsStatus={robotInsStatus}
                eStopType={eStopType}
                userEmail={userEmail}
                mqttClient={mqttClient}
                coolDownMessage={coolDownMessage}
                coolDownWarningColour={coolDownWarningColour}
              />
            </DialogContent>
          </Dialog>
        ) : null}
        <ActionsDialog
          classes={classes.dialog}
          dialogTitle={`The robot ${serialNumber} is currently suspended and there is a pending repeating operation, do you want to release the robot and cancel the current operation?`}
          open={isSuspendedRepeatingDialogOpen && !showInterRowConfirmationDialog && STATUS_MAP[robotWpsState] === EXEC_SUSPENDED}
          style={{ position: 'absolute' }}
          backdropProps={{ style: { position: 'absolute' } }}
          container={() => document.getElementById(serialNumber)}
          actions={[
            { color: 'primary', name: 'Release and Cancel', variant: 'outlined', isButtonDisabled: false, handler: handleReleaseRobot },
            {
              color: 'secondary',
              name: 'Continue Repeating',
              variant: 'contained',
              isButtonDisabled: operatingSubrowId === -1,
              handler: handleSuspendedRepeatingDialogGoBack
            }
          ]}
        />
        <ActionsDialog
          classes={classes.dialog}
          dialogTitle={`Different sub-block was selected, please select:
          (${subrowStateMessage})`}
          open={isSuspendedSubrowDifferentDialog}
          style={{ position: 'absolute' }}
          backdropProps={{ style: { position: 'absolute' } }}
          container={() => document.getElementById(serialNumber)}
          actions={[{ color: 'primary', name: 'OK', variant: 'outlined', handler: () => setIsSuspendedSubrowDifferentDialog(false) }]}
        />
      </>
    );
  }
);
export default RobotControlView;
