import React, { useEffect, useState, useRef } from 'react';
import { Box, Chip, FormControl, IconButton, Input, makeStyles, MenuItem, Select, Slider, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import { observer } from 'mobx-react-lite';
import { GpsFixed, GpsNotFixed, GpsOff, Warning } from '@material-ui/icons';
import { useStores } from '../../../store/root/root.store';
import {
  COMPLETED,
  IN_PROGRESS,
  DECK_AND_FRAME_OFFSET_SLIDER_MARKS,
  STATUS_MAP,
  AVAILABLE,
  GPS_STATUS_MAP
} from '../../../utils/constants';
import SolarMap from '../../maps/solar-map';
import PathConfigurationDialog from '../../dialogs/edit-block-subBlock-path.dialog';
import { fetchBlocks } from '../../../services/api/blocks.service';
import { fetchSubBlocks } from '../../../services/api/subblocks.service';
import { getSubBlockPathTypes } from '../../../services/api/subrows.service';
import { alphaNumericOrder, formatInterrowDirection, formatStringForReadbility, getNameFromId } from '../../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  '@keyframes flashYellowAnimation': { '0%, 100%': { color: 'black' }, '70%': { color: '#f8bb00' } },
  '@keyframes flashRedAnimation': { '0%, 100%': { color: 'black' }, '70%': { color: 'red' } },
  flashYellowWarning: { animation: '$flashYellowAnimation 1s infinite' },
  flashRedError: { animation: '$flashRedAnimation 1s infinite' },
  container: { maxWidth: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column' },
  robotData: {
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: '1px 2px',
    borderRadius: '5px',
    maxWidth: '100%',
    maxHeight: '100%',
    border: '2px solid black'
  },
  rowSelectionDropDown: {
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  formControl: {
    width: '100%',
    marginRight: theme.spacing(1),
    margin: '2px 0px',
    padding: '0px',
    maxWidth: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box'
  },
  multiSelect: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  chip: {
    margin: 0,
    height: '20px',
    maxHeight: '20px'
  },
  selected: {
    backgroundColor: 'gray !important',
    color: 'white'
  },
  completedSolarRow: {
    backgroundColor: '#2eb885',
    color: '#fff'
  },
  inProgressSolarRow: {
    backgroundColor: '#03a9f4',
    color: '#fff'
  },
  restrictedSolarRow: {
    backgroundColor: '#ed271c',
    color: '#fff'
  },
  keepOutRow: {
    backgroundColor: '#170106',
    color: '#fff'
  },
  editIcon: {
    padding: '0px',
    margin: '0px',
    width: '0px',
    '&:hover': {
      color: '#121111',
      backgroundColor: theme.palette.grey[300],
      cursor: 'point'
    }
  },
  attachmentDetails: {
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '7px',
    marginBottom: '0px',
    borderRadius: '5px',
    margin: '2px 0px',
    boxSizing: 'border-box',
    maxHeight: '100%'
  },
  typography: {
    fontSize: '12px',
    maxWidth: 'max-content',
    maxHeight: 'max-content',
    padding: '0px',
    margin: '0px'
  },
  slider: {
    boxSizing: 'border-box',
    color: 'black',
    paddingBottom: '7px',
    height: '100%'
  },
  googleMapsContainer: {
    height: '280px',
    maxHeight: '100%',
    boxSizing: 'border-box'
  },
  robotDetailContainer: {
    marginLeft: '3px'
  },
  robotDetailText: {
    marginLeft: '5px',
    fontWeight: 'bold'
  },
  toolTip: {
    fontSize: '1.1em'
  }
}));

export const ControlPanel = observer(
  ({
    solarSubRows,
    serialNumber,
    robot,
    robotLat,
    robotLng,
    robotHeading,
    cords,
    isRightOffset,
    selectedProperty,
    selectedBlockId,
    setSelectedBlockId,
    selectedSubBlockId,
    setSelectedSubBlockId,
    selectedPathType,
    setSelectedPathType,
    robotWpsState,
    cancelRepeatingTask,
    resetRobot,
    containerRef,
    updateSolarSubRows,
    robotGpsState,
    robotInsState,
    robotInsStatus,
    coolDownMessage,
    coolDownWarningColour
  }) => {
    const { MultiRobotsStore } = useStores();
    const selectedSolarSubRows = MultiRobotsStore.robots.get(serialNumber)?.selectedSolarSubRows;
    const [showDialog, setShowDialog] = useState(false);
    const blockList = useRef(null);
    const subBlockList = useRef(null);
    const subBlockPathTypes = useRef(null);
    const [selectedBlock, setSelectedBlock] = useState(selectedBlockId);
    const [selectedSubBlock, setSelectedSubBlock] = useState(selectedSubBlockId);
    const [currentPathType, setCurrentPathType] = useState(selectedPathType);
    const robotEstopState = MultiRobotsStore.robots.get(serialNumber)?.estopState;
    const interRowDirection = MultiRobotsStore.robots.get(serialNumber)?.interRowDirection;
    const deckOffset = isRightOffset
      ? parseInt(MultiRobotsStore?.robots?.get(serialNumber)?.deckOffset)
      : parseInt(MultiRobotsStore?.robots?.get(serialNumber)?.deckOffset) * -1;
    const frameOffset = isRightOffset
      ? parseInt(MultiRobotsStore?.robots?.get(serialNumber)?.frameOffset)
      : parseInt(MultiRobotsStore?.robots?.get(serialNumber)?.frameOffset) * -1;
    const linearVelocityKmH = robotEstopState
      ? 0
      : parseFloat(((MultiRobotsStore.robots.get(serialNumber).linearVelocity * 18) / 5).toFixed(1)) || '-';
    const classes = useStyles();

    const getStatusClass = (subrow) => {
      if (subrow.is_keep_out_zone) return classes.keepOutRow;
      if (subrow.is_restricted === true) return classes.restrictedSolarRow;
      if (subrow.status === COMPLETED) return classes.completedSolarRow;
      if (subrow.status === IN_PROGRESS) return classes.inProgressSolarRow;
      return '';
    };

    useEffect(() => {
      setSelectedBlock(selectedBlockId);
      setSelectedSubBlock(selectedSubBlockId);
      setCurrentPathType(selectedPathType);

      return () => {};
    }, [selectedBlockId, selectedSubBlockId]);

    /** API calls to fetch block, sub-block, and path types on drop down change in
     * PathConfiguration component
     */
    const getBlockSubBlockPathData = async () => {
      if (selectedProperty && selectedBlock && selectedSubBlock) {
        blockList.current = await fetchBlocks(selectedProperty);
        blockList.current = { results: blockList.current.results.sort(alphaNumericOrder((c) => c.name)) };
        subBlockList.current = await fetchSubBlocks(selectedBlock);
        subBlockList.current = { results: subBlockList.current.results.sort(alphaNumericOrder((c) => c.name)) };
        const paths = await getSubBlockPathTypes(selectedSubBlock);
        subBlockPathTypes.current = paths.map((path) => ({
          id: path,
          name: formatStringForReadbility(path.replace('_', ' ')),
          value: path
        }));
      }
    };
    // Handles setting and rendering GPS & INS indicators
    const setGpsStatusIcon = () => (
      <Tooltip
        title={
          // eslint-disable-next-line no-nested-ternary
          robotGpsState === GPS_STATUS_MAP.FIXED_RTK || robotGpsState === GPS_STATUS_MAP.FLOAT_RTK ? (
            <Typography variant="h6">{`${robotGpsState} -- ${robotInsStatus}`}</Typography>
          ) : (
            <Typography variant="h6"> {robotGpsState} </Typography>
          )
        }
      >
        {
          // eslint-disable-next-line no-nested-ternary
          robotGpsState === GPS_STATUS_MAP.FIXED_RTK ? (
            <GpsFixed style={{ color: `${robotInsState ? '#369a03' : '#e7ae00'}`, fontWeight: 'bold' }} />
          ) : robotGpsState === GPS_STATUS_MAP.FLOAT_RTK ? (
            <GpsNotFixed className={classes.flashYellowWarning} />
          ) : (
            <GpsOff className={classes.flashRedError} />
          )
        }
      </Tooltip>
    );

    useEffect(() => {
      setGpsStatusIcon();
    }, [robotGpsState]);

    useEffect(() => {
      getBlockSubBlockPathData();
    }, [selectedBlock, selectedSubBlock]);

    const handleConfigureBlockandPath = () => {
      setShowDialog(true);
    };

    const handleCloseDialog = () => {
      setShowDialog(false);
    };

    useEffect(() => {
      updateSolarSubRows();
    }, [showDialog]);

    return (
      <div className={classes.container}>
        <div>
          {blockList.current && subBlockList.current && subBlockPathTypes.current ? (
            <PathConfigurationDialog
              robot={robot}
              show={showDialog}
              hide={() => setShowDialog(false)}
              containerRef={containerRef}
              handleClose={handleCloseDialog}
              blockList={blockList.current}
              subBlockList={subBlockList.current}
              subBlockPathList={subBlockPathTypes.current}
              selectedBlockId={selectedBlockId}
              setSelectedBlockId={setSelectedBlockId}
              selectedSubBlockId={selectedSubBlockId}
              setSelectedSubBlockId={setSelectedSubBlockId}
              selectedPathType={currentPathType}
              setSelectedPathType={setSelectedPathType}
              selectedBlock={selectedBlock}
              setSelectedBlock={setSelectedBlock}
              selectedSubBlock={selectedSubBlock}
              setSelectedSubBlock={setSelectedSubBlock}
              currentPathType={currentPathType}
              setCurrentPathType={setCurrentPathType}
              cancelRepeatingTask={cancelRepeatingTask}
              robotWpsState={robotWpsState}
            />
          ) : (
            <div />
          )}
        </div>
        <div style={{ width: '100%', height: '100%', maxHeight: '100%', maxWidth: '100%' }}>
          {blockList.current && subBlockList.current ? (
            <div className={classes.robotData}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className={classes.robotDetailContainer}>
                  Robot:
                  <Typography variant="p" className={classes.robotDetailText}>
                    {robot.name}
                  </Typography>
                </span>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {setGpsStatusIcon()}
                  <IconButton size="small" className={classes.editIcon} style={{ margin: '0 10px' }}>
                    <SettingsIcon fontSize="small" onClick={() => handleConfigureBlockandPath()} />
                  </IconButton>
                  {STATUS_MAP[robotWpsState] !== AVAILABLE && (
                    <Tooltip title="Cancel Path" aria-label="Cancel Path">
                      <IconButton size="small" className={classes.editIcon} style={{ margin: '0 10px' }}>
                        <ClearIcon fontSize="small" onClick={() => cancelRepeatingTask()} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <span className={classes.robotDetailContainer}>
                Site:
                <Typography variant="p" className={classes.robotDetailText}>
                  {formatStringForReadbility(robot.siteName)}
                </Typography>
              </span>
              <span className={classes.robotDetailContainer}>
                Block:
                <Typography variant="p" className={classes.robotDetailText}>
                  {formatStringForReadbility(getNameFromId(selectedBlockId, blockList.current.results))}
                </Typography>
              </span>
              <span className={classes.robotDetailContainer}>
                Sub-Block:
                <Typography variant="p" className={classes.robotDetailText}>
                  {formatStringForReadbility(getNameFromId(selectedSubBlockId, subBlockList.current.results))}
                </Typography>
              </span>
              <span className={classes.robotDetailContainer}>
                PathType:
                <Typography variant="p" className={classes.robotDetailText}>
                  {formatStringForReadbility(selectedPathType)}
                </Typography>
              </span>
              <span className={classes.robotDetailContainer}>
                Battery:
                <Typography variant="p" className={classes.robotDetailText}>
                  {`${formatStringForReadbility(robot.batteryLevel)}%`}
                </Typography>
              </span>
              <span className={classes.robotDetailContainer}>
                Speed:
                <Typography variant="p" className={classes.robotDetailText}>
                  {`${linearVelocityKmH} Km/H`}
                </Typography>
              </span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Cool Down:
                <span
                  style={{
                    marginLeft: '5px'
                  }}
                >
                  <Tooltip classes={{ tooltip: classes.toolTip }} title={coolDownMessage}>
                    <Warning fontSize="medium" key="oil" style={{ color: `${coolDownWarningColour}` }} />
                  </Tooltip>
                </span>
              </span>
              <span className={classes.robotDetailContainer}>
                Inter-Row Direction:
                <Typography variant="p" className={classes.robotDetailText}>
                  {formatInterrowDirection(interRowDirection)}
                </Typography>
              </span>
            </div>
          ) : (
            <div />
          )}
          {solarSubRows && (
            <div className={classes.rowSelectionDropDown}>
              <FormControl className={classes.formControl}>
                <Select
                  multiple
                  value={selectedSolarSubRows}
                  className={classes.multiSelect}
                  style={{ maxHeight: '100%' }}
                  onChange={(event) => {
                    MultiRobotsStore.setSelectedSolarSubRows(serialNumber, event.target.value);
                  }}
                  input={<Input />}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={value} label={value.substring(0, value.indexOf('__'))} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {solarSubRows.map((subrow) => (
                    <MenuItem
                      selected
                      disabled={subrow.status === IN_PROGRESS || subrow.is_restricted === true || subrow.is_keep_out_zone}
                      className={getStatusClass(subrow)}
                      key={subrow.id}
                      value={subrow.value}
                    >
                      {subrow.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <Box className={classes.attachmentDetails}>
            <Typography id="deck-offset" className={classes.typography}>
              Deck Offset: {deckOffset} cm
            </Typography>
            <Slider
              aria-labelledby="deck-offset"
              min={-76}
              max={76}
              step={1}
              value={deckOffset}
              marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.deckOffset}
              disabled
              className={classes.slider}
            />
            <Typography id="frame-offset" className={classes.typography}>
              Carriage Offset: {frameOffset} cm
            </Typography>
            <Slider
              aria-labelledby="frame-offset"
              min={-65}
              max={65}
              step={1}
              value={frameOffset}
              marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.frameOffset}
              disabled
              className={classes.slider}
            />
          </Box>
        </div>
        <Box className={classes.googleMapsContainer}>
          <SolarMap
            key={robotLat && robotLat !== 0 ? 'loaded' : 'loading'}
            robotLat={robotLat}
            resetRobot={resetRobot}
            mapData={cords}
            robotLng={robotLng}
            robotHeading={robotHeading}
            selectedSubRows={selectedSolarSubRows}
            height="100%"
            className={false}
          />
        </Box>
      </div>
    );
  }
);
