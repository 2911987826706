import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Paper,
  Box,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Tab,
  Tabs
} from '@material-ui/core';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { observer } from 'mobx-react-lite';
import axios from 'axios';

import { DialogTitle } from '../core/dialog.component';
import { useStores } from '../../store/root/root.store';
import { client } from '../../utils/axios-instance';
import { ChaperoneRobotConnectionService } from '../../services/chaperone/robot-connection.service';
import {
  EDIT_ROBOTS_CONFIG_TITLES,
  IN_USE,
  robotParameterKeys,
  ROS_PARAM_GROUP_MAP,
  CONFIGURE_ROBOTS_INFO_DIALOGUE
} from '../../utils/constants';
import ActionsDialog from './actions.dialog';
import UnsavedChangesDialog from './unsaved-changes.dialog';
import { isDevMode, assignParamReference, formatStringForReadbility, configPayloadObjectConstructor } from '../../utils/ui.utils';
import ProgressModal from './ProgressModal';
import ConnectionErrorDialog from './connection-error.dialog';
import PortalConfigurations from '../inputs/editRobotsInput/PortalConfiguration';
import AutonomyConfigurations from '../inputs/editRobotsInput/AutonomyConfigurations';
import SystemConfigurations from '../inputs/editRobotsInput/SystemConfigurations';
import HardwareConfigurations from '../inputs/editRobotsInput/HardwareConfigurations';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  tertiary: {
    color: '#0182f5',
    borderColor: '#0182f5'
  },
  uppercase: {
    '& > input': {
      textTransform: 'uppercase'
    },
    '& > input::placeholder': {
      textTransform: 'none'
    }
  },
  helperText: {
    color: 'red'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  circularProgress: {
    color: theme.palette.inverted.main
  }
}));

/**
 * ConfigureRobot - Robot system configuration component
 *
 * This component is responsible for configuring various settings related to Autonomy, System, and others on Swapbot.
 * The dialogue component uses store values from contorl.store and ROS connection to retrieve data and send commands.
 * @param {Object} robot An object containing robot state data for the selected robot
 * @param {Function} onClose Handles closing dialogue
 * @param {Boolean} isSelectedRobotConnectedROS Checks if the robot currently being configured has an active ROS connection
 * @param {Object} isSelectedRobotInUse Checks if robot is currently being used for autonomous operations
 * @returns {Component} React Component
 */

const ConfigureRobot = observer(({ robot = null, onClose = () => {} }) => {
  const classes = useStyles();
  const { autonomyRobotStore, countriesStore, regionsStore, applicationStore, controlStore } = useStores();
  const homeProperty = robot.home_property_id;

  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [robots, setRobots] = useState([]);
  const [robotName, setRobotName] = useState('');
  const [currRegion, setCurrRegion] = useState('');
  const [currProperty, setCurrProperty] = useState('');
  const [currProperties, setCurrProperties] = useState([]);
  const [useCase, setUseCase] = useState('');
  const [country, setCountry] = useState('');
  const portalTarget = process.env.REACT_APP_ENV;
  const [robotDeploymentTarget, setrobotDeploymentTarget] = useState('DEFAULT');
  const [openContentInfoDialogue, setOpenContentInfoDialogue] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [openROSDisconnectedDialog, setOpenROSDisconnectedDialog] = useState(false);
  const [ROSDisconnectedDialogTitle, setROSDisconnectedDialogTitle] = useState('');
  const [duplicates, setDuplicates] = useState([]);
  const [formError, setFormError] = useState(false);
  const isMountedRef = useRef(null);
  const robotConnection = useRef(null);
  const robotConnectionService = useRef(null);
  const [autonomyNavigationControllerOptions, setAutonomyNavigationControllerOptions] = useState([]);
  const [autonomyAttachmentControllerOptions, setautonomyAttachmentControllerOptions] = useState([]);
  const [showDisableZedDiagnosticWarning, setShowDisableZedDiagnosticWarning] = useState(false);
  const [serviceCallStatus, setServiceCallStatus] = useState('');
  const [serviceCallMessage, setServiceCallMessage] = useState('');
  const [progressModalTitle, setProgressModalTitle] = useState('');
  const [showProgressModal, setShowProgressModal] = useState(false);
  const userName = localStorage.getItem('username');
  const [openRestartDialog, setOpenRestartDialog] = useState(false);
  const [robotConnectionIndicator, setRobotConnectionIndicator] = useState('');
  const [robotConnectionMessage, setRobotConnectionMessage] = useState('');
  // tracks the currently selected Tab
  const [tabPage, setTabPage] = useState(0);
  const closeDialogue = () => setOpenRestartDialog(false);
  const closeROSDisconnectDialog = () => setOpenROSDisconnectedDialog(false);
  const selectedSerialNumber = autonomyRobotStore.getSelectedRobot()?.serial_number;
  const selectedrobotDeploymentTarget = controlStore.robotDeploymentTarget || 'DEFAULT';
  const [isSelectedRobotConnectedROS, setIsSelectedRobotConnectedROS] = useState(false);
  const [selectedSettingTitle, setSelectedSettingTitle] = useState(EDIT_ROBOTS_CONFIG_TITLES[0].name);
  const isSelectedRobotInUse = robot.status === IN_USE;
  const robotIsEstopped =
    autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.estop_is_on ||
    autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.sw_estop_is_on;

  /** Initial configuration values for robot from DB api
   */
  const initialRobotDbValues = useRef({});

  /** Initial configuration values for dynamic and static ROS parameters
   */
  const initialRobotRosValues = useRef({});
  initialRobotRosValues.current.portalTarget = process.env.REACT_APP_ENV;

  /** State variables for UI changes. uiParameters is an object in {paramaterName:parameterValues} format
   *  uiParameters will be used to render parameter values in component and handle user interaction
   */
  const [uiParameters, setUiParameters] = useState(() =>
    robotParameterKeys.reduce((acc, paramName) => {
      acc[paramName] = controlStore?.paramName !== undefined ? controlStore?.paramName : null;
      return acc;
    }, {})
  );
  /** Returns true if any one of the values is different from its value at initialization */
  const isRestartRequired = () => {
    const restartRobot =
      portalTarget !== initialRobotRosValues.current?.portalTarget ||
      robotDeploymentTarget !== initialRobotRosValues.current.robotDeploymentTarget ||
      uiParameters.autonomyNavigationDeviationCheck !== initialRobotRosValues.current?.autonomyNavigationDeviationCheck ||
      uiParameters.attachmentType !== initialRobotRosValues.current?.attachmentType ||
      uiParameters.autonomyAttachmentController !== initialRobotRosValues.current.autonomyAttachmentController ||
      uiParameters.enableManualBladesDuringAutonomy !== initialRobotRosValues.current.enableManualBladesDuringAutonomy ||
      uiParameters.enableAutonomousBladesDuringAutonomy !== initialRobotRosValues.current.enableAutonomousBladesDuringAutonomy ||
      uiParameters.enableAutonomousAttachmentControl !== initialRobotRosValues.current.enableAutonomousAttachmentControl ||
      uiParameters.enableInterRowHopping !== initialRobotRosValues.current.enableInterRowHopping ||
      uiParameters.acceptableZedLevel !== initialRobotRosValues.current.acceptableZedLevel ||
      uiParameters.autonomyNavigationController !== initialRobotRosValues.current.autonomyNavigationController ||
      uiParameters.enableDevBot !== initialRobotRosValues.current.enableDevBot ||
      uiParameters.attachmentOffsetDirection !== initialRobotRosValues.current?.attachmentOffsetDirection ||
      uiParameters?.interrowType !== initialRobotRosValues?.current.interrowType ||
      uiParameters?.overworkedMotorsCheck !== initialRobotRosValues?.current.overworkedMotorsCheck ||
      uiParameters?.dynamicMowingSpeed !== initialRobotRosValues?.current.dynamicMowingSpeed ||
      uiParameters?.stuckAutonomyCheck !== initialRobotRosValues?.current.stuckAutonomyCheck ||
      uiParameters?.stuckSpeedThreshold !== initialRobotRosValues?.current.stuckSpeedThreshold ||
      uiParameters?.stuckExecutionProgress !== initialRobotRosValues?.current.stuckExecutionProgress ||
      uiParameters?.stuckCurrentThreshold !== initialRobotRosValues?.current.stuckCurrentThreshold ||
      uiParameters?.stuckTimeout !== initialRobotRosValues?.current.stuckTimeout ||
      uiParameters?.enableRosbagRecording !== initialRobotRosValues?.current.enableRosbagRecording ||
      uiParameters?.enableRosbagUploading !== initialRobotRosValues?.current.enableRosbagUploading ||
      uiParameters?.enableBaseSensor !== initialRobotRosValues?.current.enableBaseSensor ||
      uiParameters?.enableTankSensor !== initialRobotRosValues?.current.enableTankSensor ||
      uiParameters?.enableAdasChaperone !== initialRobotRosValues?.current.enableAdasChaperone ||
      uiParameters?.coolingHoseEnabled !== initialRobotRosValues.current?.coolingHoseEnabled ||
      uiParameters?.deckPotWorks !== initialRobotRosValues.current?.deckPotWorks ||
      uiParameters?.deckPotHomeValue !== initialRobotRosValues.current?.deckPotHomeValue ||
      uiParameters?.framePotWorks !== initialRobotRosValues.current?.framePotWorks ||
      uiParameters?.framePotHomeValue !== initialRobotRosValues.current?.framePotHomeValue;
    return restartRobot;
  };

  const isRobotConfigChanged = () => {
    let isConfigChanged = false;
    if (robot) {
      isConfigChanged =
        uiParameters.attachmentType !== initialRobotRosValues.current?.attachmentType ||
        robotDeploymentTarget !== initialRobotRosValues.current?.robotDeploymentTarget ||
        uiParameters.autonomyNavigationDeviationCheck !== initialRobotRosValues.current?.autonomyNavigationDeviationCheck ||
        uiParameters.deviationPositionSlowThreshold !== initialRobotRosValues.current?.deviationPositionSlowThreshold ||
        uiParameters.deviationPositionStopThreshold !== initialRobotRosValues.current?.deviationPositionStopThreshold ||
        uiParameters.deviationPositionSlowSpeed !== initialRobotRosValues.current?.deviationPositionSlowSpeed ||
        uiParameters.deviationHeadingSlowThreshold !== initialRobotRosValues.current.deviationHeadingSlowThreshold ||
        uiParameters.deviationHeadingStopThreshold !== initialRobotRosValues.current.deviationHeadingStopThreshold ||
        portalTarget !== initialRobotRosValues.current?.portalTarget ||
        uiParameters.enableManualBladesDuringAutonomy !== initialRobotRosValues.current.enableManualBladesDuringAutonomy ||
        uiParameters.enableAutonomousBladesDuringAutonomy !== initialRobotRosValues.current.enableAutonomousBladesDuringAutonomy ||
        uiParameters.acceptableZedLevel !== initialRobotRosValues.current.acceptableZedLevel ||
        uiParameters.enableDevBot !== initialRobotRosValues.current.enableDevBot ||
        uiParameters.enableAutonomousAttachmentControl !== initialRobotRosValues.current.enableAutonomousAttachmentControl ||
        uiParameters.enableInterRowHopping !== initialRobotRosValues.current.enableInterRowHopping ||
        uiParameters.isLowStreamingEnabled !== initialRobotRosValues.current.isLowStreamingEnabled ||
        uiParameters.isTeleopsStreamingEnabled !== initialRobotRosValues.current.isTeleopsStreamingEnabled ||
        uiParameters.autonomyNavigationController !== initialRobotRosValues.current.autonomyNavigationController ||
        uiParameters.autonomyAttachmentController !== initialRobotRosValues.current.autonomyAttachmentController ||
        uiParameters.autonomyNavigationMode !== initialRobotRosValues.current?.autonomyNavigationMode ||
        uiParameters.attachmentOffsetDirection !== initialRobotRosValues.current?.attachmentOffsetDirection ||
        uiParameters?.interrowType !== initialRobotRosValues?.current.interrowType ||
        uiParameters?.overworkedMotorsCheck !== initialRobotRosValues?.current.overworkedMotorsCheck ||
        uiParameters?.dynamicMowingSpeed !== initialRobotRosValues?.current.dynamicMowingSpeed ||
        uiParameters?.stuckAutonomyCheck !== initialRobotRosValues?.current.stuckAutonomyCheck ||
        uiParameters?.stuckSpeedThreshold !== initialRobotRosValues?.current.stuckSpeedThreshold ||
        uiParameters?.stuckExecutionProgress?.toFixed(3) !== initialRobotRosValues?.current.stuckExecutionProgress?.toFixed(3) ||
        uiParameters?.stuckCurrentThreshold !== initialRobotRosValues?.current.stuckCurrentThreshold ||
        uiParameters?.stuckTimeout !== initialRobotRosValues?.current.stuckTimeout ||
        uiParameters?.enableRosbagRecording !== initialRobotRosValues?.current.enableRosbagRecording ||
        uiParameters?.enableRosbagUploading !== initialRobotRosValues?.current.enableRosbagUploading ||
        uiParameters?.enableBaseSensor !== initialRobotRosValues?.current.enableBaseSensor ||
        uiParameters?.enableTankSensor !== initialRobotRosValues?.current.enableTankSensor ||
        uiParameters?.enableAdasChaperone !== initialRobotRosValues?.current.enableAdasChaperone ||
        uiParameters?.coolingHoseEnabled !== initialRobotRosValues.current?.coolingHoseEnabled ||
        uiParameters?.deckPotWorks !== initialRobotRosValues.current?.deckPotWorks ||
        uiParameters?.deckPotHomeValue !== initialRobotRosValues.current?.deckPotHomeValue ||
        uiParameters?.framePotWorks !== initialRobotRosValues.current?.framePotWorks ||
        uiParameters?.framePotHomeValue !== initialRobotRosValues.current?.framePotHomeValue;
    }
    return isConfigChanged;
  };
  // mointors configurations changes in hardware settings
  const isHardwareConfigChanged = () => {
    let isHardareconfigchanged;
    if (robot) {
      isHardareconfigchanged =
        uiParameters?.coolingHoseEnabled !== initialRobotRosValues.current?.coolingHoseEnabled ||
        uiParameters?.deckPotWorks !== initialRobotRosValues.current?.deckPotWorks ||
        uiParameters?.deckPotHomeValue !== initialRobotRosValues.current?.deckPotHomeValue ||
        uiParameters?.framePotWorks !== initialRobotRosValues.current?.framePotWorks ||
        uiParameters?.framePotHomeValue !== initialRobotRosValues.current?.framePotHomeValue;
    }
    return isHardareconfigchanged;
  };

  const isWebPortalConfigChanged = () => {
    let isPortalConfigChanged = false;
    if (robot) {
      isPortalConfigChanged =
        useCase !== initialRobotDbValues.current?.useCase ||
        country !== initialRobotDbValues.current?.country ||
        robotName !== initialRobotDbValues.current?.robotName ||
        currRegion !== initialRobotDbValues.current?.currRegion ||
        currProperty !== initialRobotDbValues.current?.currProperty;
    }
    return isPortalConfigChanged;
  };

  const isFormUpdated = isRobotConfigChanged() || isWebPortalConfigChanged();

  const updatePortalConfigurationRefs = () => {
    initialRobotDbValues.current.useCase = useCase;
    initialRobotDbValues.current.country = country;
    initialRobotDbValues.current.robotName = robotName;
    initialRobotDbValues.current.currRegion = currRegion;
    initialRobotDbValues.current.currProperty = currProperty;
  };

  const closeDialogueAndUpdatePortalRefs = () => {
    updatePortalConfigurationRefs();
    closeDialogue();
  };

  useEffect(() => {
    axios
      .get(`${applicationStore.getChaperoneBaseUrl()}/robots/`)
      .then((res) => setRobots(res.data.results || []))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (robot) {
      setCurrRegion(robot.current_region_id);
      setCountry(robot.current_country_id);
      setCurrProperty(robot.current_property_id);
      setRobotName(robot.name);
      setUseCase(robot.use_case);
      initialRobotDbValues.current.currRegion = robot.current_region_id;
      initialRobotDbValues.current.country = robot.current_country_id;
      initialRobotDbValues.current.currProperty = robot.current_property_id;
      initialRobotDbValues.current.robotName = robot.name;
      initialRobotDbValues.current.useCase = robot.use_case;
    }
  }, [robot]);

  useEffect(() => {
    if (selectedrobotDeploymentTarget) {
      setrobotDeploymentTarget(selectedrobotDeploymentTarget);
      initialRobotRosValues.current.robotDeploymentTarget = selectedrobotDeploymentTarget;
    }
  }, [selectedrobotDeploymentTarget]);

  useEffect(() => {
    countriesStore.getCountries();
  }, [useCase]);

  useEffect(() => {
    if (currRegion) {
      client
        .get('/properties', { params: { region: currRegion } })
        .then((res) => {
          setCurrProperties(res.data.results || []);
          if (res.data.results.length === 1) setCurrProperty(res.data.results[0].id);
        })
        .catch(() => {
          console.log('Error in getting the region properties');
        });
    }
  }, [currRegion]);

  useEffect(() => {
    const fetchData = async () => {
      if (country) {
        await regionsStore.getRegions(country);
      }
      if (regionsStore.regions.length === 1) {
        setCurrRegion(regionsStore.regions[0].id);
      }
    };

    fetchData();
  }, [country]);

  const handleConnectionError = () => {
    if (isDevMode) {
      return;
    }
    setConnectionError(true);
    setErrorMessage("An error occurred, check the robot's internet connection and if the issue occurs again, try to reboot the robot.");
  };

  const checkDuplicates = () => {
    const duplicateName = robots.find(
      (mappedRobot) => mappedRobot.id !== robot.id && mappedRobot.name === robotName && mappedRobot.home_property_id === homeProperty
    );
    if (duplicateName) duplicates.push('robotName');
    return duplicates;
  };

  useEffect(() => {
    // Confirm the robot has a WebSocket connection to the gateway
    (async () => {
      if (autonomyRobotStore.selectedRobotId) {
        // Running async code in the effect, we need to track component is mounted
        isMountedRef.current = true;
        try {
          // On first load, initiate the robot connection
          if (isMountedRef.current && robotConnection.current === null) {
            console.debug('Connecting to robot ', autonomyRobotStore.selectedRobotId);
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              async () => {
                // onConnected
                robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robotState) =>
                  controlStore.updateRobotState(robotState)
                );
                await robotConnectionService?.current?.ros.getUpdatedStaticConfig((updatedParams) => {
                  if (updatedParams) {
                    controlStore.updateStaticParameters(updatedParams);
                  }
                });
                await robotConnectionService?.current?.ros.getUpdatedDynamicConfig((updatedParams) => {
                  if (updatedParams) {
                    controlStore.updateDynamicParameters(updatedParams);
                  }
                });
                robotConnection.current = robotConnectionService.current;
              },
              () => {
                // onDisconnect
                console.log('Lost connection to robot');
                controlStore.resetStore();
                if (robotConnection.current) handleConnectionError();
                robotConnectionService?.current?.retryConnection();
                robotConnection.current = null;
              },
              selectedSerialNumber,
              userName,
              'edit_robot'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
        }
      }
    })();

    robotConnectionService?.current?.connectToRobot(handleConnectionError);
    // Cleanup function
    return () => {
      isMountedRef.current = false;
      if (robotConnectionService.current !== null) {
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
      }
      autonomyRobotStore.clearSelectedRobot();
    };
    // eslint-disable-next-line
  }, []);

  /** Updates UiParameters values with control store values to sync store and component state */
  useEffect(() => {
    const newUiState = robotParameterKeys.reduce((acc, paramName) => {
      acc[paramName] = controlStore[paramName] !== undefined ? controlStore[paramName] : null;
      return acc;
    }, {});
    setUiParameters(newUiState);
    assignParamReference(initialRobotRosValues, newUiState);
  }, [
    controlStore.autonomyNavigationController,
    controlStore.autonomyNavigationMode,
    controlStore.attachmentType,
    controlStore.autonomyAttachmentController
  ]);

  /**
   * Responsible for rendering Progress modal and showing
   * configurations were applied successfuly
   * @return {None}
   */
  const handleSuccessfulConfig = () => {
    setServiceCallStatus('success');
    setProgressModalTitle('');
    setServiceCallMessage('Configurations Have Successfully Been Applied');
    setTimeout(() => {
      setShowProgressModal(false);
      setServiceCallMessage('');
      onClose();
    }, 2500);
  };

  /**
   * Responsible for rendering Progress modal and showing
   * configurations were NOT applied successfuly
   * @return {None}
   */
  const handleUnsuccessfulConfig = () => {
    setServiceCallStatus('error');
    setProgressModalTitle('');
    setServiceCallMessage('Encountered Error with Robot Configuration. Please Contact the Autonomy Team');
    setTimeout(() => {
      setShowProgressModal(false);
      setServiceCallMessage('');
    }, 2500);
  };

  /**
   * Handles mounting Progress Modal component
   */
  const handleProgressModal = () => {
    setShowProgressModal(true);
    setProgressModalTitle('Applying Changes');
    setServiceCallStatus('loading');
  };

  /**
   * Function handles building configuration payload and
   * applying configurations
   * @return None
   */
  const saveRobotConfigChanges = async () => {
    /**
     * Some parameters must be formatted as strings for the robot to accept them
     */
    // eslint-disable-next-line no-new-object
    const formattedUiParams = new Object(uiParameters);
    // eslint-disable-next-line no-new-object
    const formattedParamMap = new Object(ROS_PARAM_GROUP_MAP);
    const structuredObject = configPayloadObjectConstructor(formattedParamMap, formattedUiParams);
    structuredObject.system.misc.useCase = useCase;
    structuredObject.system.misc.robotDeploymentTarget = robotDeploymentTarget;
    structuredObject.isRestartRequired = isRestartRequired() ? 'true' : '';
    // extract hardware config payload, add restart required attribute, and serialize as JSON object
    const hardwareConfigPayload = structuredObject.hardware;
    hardwareConfigPayload.isRestartRequired = 'true';
    delete structuredObject.hardware;
    const serializedPayload = JSON.stringify(structuredObject);
    const serializedHardwareConfig = JSON.stringify(hardwareConfigPayload);
    if (isHardwareConfigChanged() && isRobotConfigChanged()) {
      handleProgressModal();
      await robotConnection?.current?.ros?.cmdRobotPayloadService('HARDWARE_CONFIG', serializedHardwareConfig, (result) => {
        if (result) {
          if (!result.success || result.error_message) {
            console.error(result.error_message);
            handleUnsuccessfulConfig();
          } else {
            handleSuccessfulConfig();
          }
        }
      });
    } else if (isRobotConfigChanged() && !isHardwareConfigChanged()) {
      handleProgressModal();
      await robotConnection?.current?.ros?.cmdRobotPayloadService('CONFIG', serializedPayload, (result) => {
        if (result) {
          if (!result.success || result.error_message) {
            console.error(result.error_message);
            handleUnsuccessfulConfig();
          } else {
            handleSuccessfulConfig();
          }
        }
      });
    }
  };

  const savePortalConfigChanges = () => {
    const data = {
      name: robotName,
      current_property_id: currProperty,
      use_case: useCase
    };
    client
      .patch(`/robots/${robot.id}`, data)
      .then(() => {
        autonomyRobotStore.getRobots();
      })
      .catch((err) => {
        console.log(`Error on editing the robot ${err}`);
      });
  };

  const saveRobotConfigChangesAndClose = async () => {
    await saveRobotConfigChanges();
  };

  const saveChanges = async () => {
    savePortalConfigChanges();
    await saveRobotConfigChanges();
  };

  /** Handler for Save button */
  const handleSave = () => {
    const duplicateFields = checkDuplicates();
    if (duplicateFields.length) {
      setDuplicates(duplicateFields);
      return;
    }

    setDuplicates([]);

    if (useCase && country && robotName && currRegion && currProperty) {
      if (robotConnection.current === null) {
        if (isRobotConfigChanged()) {
          if (isWebPortalConfigChanged()) {
            saveChanges();
            updatePortalConfigurationRefs();
            setROSDisconnectedDialogTitle(
              'Because there is no ROS connection to the robot, only the changes to the Portal Configuration have been saved.'
            );
          } else {
            setROSDisconnectedDialogTitle(
              'The changes to the Robot Configuration were not able to be saved because there is no ROS connection to the robot.'
            );
          }
          setOpenROSDisconnectedDialog(true);
        } else {
          // Save portal configuration changes and close
          savePortalConfigChanges();
          onClose();
        }
      } else if (isRobotConfigChanged() && isWebPortalConfigChanged()) {
        savePortalConfigChanges();
        if (isRestartRequired()) {
          setOpenRestartDialog(true);
        } else {
          saveRobotConfigChangesAndClose();
        }
      } else if (isRobotConfigChanged()) {
        if (isRestartRequired()) {
          setOpenRestartDialog(true);
        } else {
          saveRobotConfigChangesAndClose();
        }
      } else {
        savePortalConfigChanges();
        onClose();
      }
    } else {
      // Set form error to true
      setFormError(true);
    }
  };

  function handleClose() {
    if (isFormUpdated) setOpenUnsavedChangesDialog(true);
    else onClose();
  }

  function handleConfirmClose(doCloseFully) {
    setOpenUnsavedChangesDialog(false);
    if (doCloseFully) onClose();
  }

  /** Handler for ROS connection state */
  useEffect(() => {
    if (robotConnectionService.current?.ros?.ros?.isConnected === false && robotConnection.current !== null) {
      setRobotConnectionIndicator('yellow');
      setRobotConnectionMessage('TRYING TO RECONNECT');
    } else if (robotConnection.current === null) {
      setRobotConnectionIndicator('red');
      setRobotConnectionMessage('DISCONNECTED');
    } else {
      setRobotConnectionIndicator('green');
      setRobotConnectionMessage('CONNECTED');
      setIsSelectedRobotConnectedROS(true);
    }
  }, [robotConnectionService?.current?.hasConnected, robotConnection.current]);

  /**
   * Handler for Confirm button on Disable ZED diagnostic warning
   */
  const handleDisableZedDiagnosticConfirmation = () => {
    const paramName = 'acceptableZedLevel';
    setUiParameters((previous) => ({
      ...previous,
      [paramName]: 1
    }));
    setShowDisableZedDiagnosticWarning(false);
  };

  /**
   * Handler for Cancel button on Disable ZED diagnostic warning
   */
  const handleDisableZedDiagnosticCancel = () => {
    const paramName = 'acceptableZedLevel';
    setUiParameters((previous) => ({
      ...previous,
      [paramName]: 0
    }));
    setShowDisableZedDiagnosticWarning(false);
  };

  /**
   * Function to handle 'Disable Zed Diagnostic' warning.
   * Dialog will propmpt user to continue or cancel action
   * @param {Boolean} disableZedDiagnositc - true if user confirms dialog warning
   */
  const handleShowDisableZedDiagnosticWarning = (disableZedDiagnositc) => {
    if (disableZedDiagnositc) {
      setShowDisableZedDiagnosticWarning(true);
    } else {
      handleDisableZedDiagnosticCancel();
    }
  };

  /** Makes a service call to dynamically retrieve attachment controller types based attachment
   *  Service call also retrieves avaliable motion controllers.
   *  The default value for Motion controller will always be on the 0th index
   */
  useEffect(() => {
    robotConnectionService?.current?.ros.cmdGetAttachmentDetails((result) => {
      const results = JSON.parse(result.message);
      setAutonomyNavigationControllerOptions(results.motion);
      if (uiParameters.attachmentType !== undefined && uiParameters.attachmentType in results) {
        setautonomyAttachmentControllerOptions(results[uiParameters.attachmentType]);
      } else {
        setautonomyAttachmentControllerOptions([]);
      }
    });
  }, [robot, uiParameters.attachmentType]);

  /** Handles updates to UI state variables used for user interaction. */
  const handleUiParametersChange = useCallback((event, checkBoxChange, number) => {
    if (checkBoxChange) {
      const { name, checked } = event.target;
      setUiParameters((previous) => ({
        ...previous,
        [name]: checked
      }));
    } else if (number) {
      const { name, value } = event.target;
      const formattedValue = Number(value);
      setUiParameters((previous) => ({
        ...previous,
        [name]: formattedValue
      }));
    } else {
      const { name, value } = event.target;
      setUiParameters((previous) => ({
        ...previous,
        [name]: value
      }));
    }
  });

  /**
   * Hanldes changing between tab groups
   * @param {Object} event event object passed from onChange event handler
   * @param {Number} newValue New tab to navigate to
   */
  const handleChange = useCallback((event, newValue) => {
    event.stopPropagation();
    setTabPage(newValue);
    setSelectedSettingTitle(EDIT_ROBOTS_CONFIG_TITLES[newValue].name);
  });

  return (
    <>
      <ConnectionErrorDialog open={connectionError} handleClose={() => setConnectionError(false)} errorMessage={errorMessage} />
      <Dialog onClose={() => handleClose()} open fullWidth fullScreen={isSmall} maxWidth="lg">
        <ProgressModal open={showProgressModal} status={serviceCallStatus} message={serviceCallMessage} title={progressModalTitle} />
        <DialogTitle onClose={() => handleClose()}>
          <Tooltip title={<Typography>{`ROS Connection Status: ${robotConnectionMessage}`}</Typography>}>
            <SettingsInputHdmiIcon style={{ color: robotConnectionIndicator }} />
          </Tooltip>
          <Typography variant="">{`${robot?.name ? formatStringForReadbility(robot?.name) : 'Robot'} Configuration`}</Typography>
        </DialogTitle>
        <DialogContent style={{ minHeight: !isSmall ? '850px' : '' }}>
          <Tabs
            value={tabPage}
            onChange={handleChange}
            aria-label="settings"
            variant="fullWidth"
            centered
            indicatorColor="secondary"
            textColor="primary"
          >
            <Tab label={<Typography variant="h6">Autonomy Settings</Typography>} />
            <Tab label={<Typography variant="h6">Site Settings</Typography>} />
            <Tab label={<Typography variant="h6">System Settings</Typography>} />
            <Tab label={<Typography variant="h6">Hardware Settings</Typography>} />
          </Tabs>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '20px', marginBottom: '10px' }}
          >
            <Box mb={1}>
              <Typography variant="h4" align="center">
                {selectedSettingTitle}
                <Tooltip title={<Typography>Show additional information</Typography>}>
                  <IconButton size="small" onClick={() => setOpenContentInfoDialogue(true)}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Box>
          </Grid>
          <Paper component="form" elevation={0} color="inverted">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {tabPage === 0 && (
                <AutonomyConfigurations
                  isSelectedRobotConnectedROS={isSelectedRobotConnectedROS}
                  isSelectedRobotInUse={isSelectedRobotInUse}
                  robotDeploymentTarget={robotDeploymentTarget}
                  setrobotDeploymentTarget={setrobotDeploymentTarget}
                  formError={formError}
                  autonomyNavigationControllerOptions={autonomyNavigationControllerOptions}
                  autonomyAttachmentControllerOptions={autonomyAttachmentControllerOptions}
                  uiParameters={uiParameters}
                  handleUiParametersChange={handleUiParametersChange}
                  setUiParameters={setUiParameters}
                  portalTarget={portalTarget}
                />
              )}
              {tabPage === 1 && (
                <PortalConfigurations
                  classes={classes}
                  robot={robot}
                  formError={formError}
                  useCase={useCase}
                  setUseCase={setUseCase}
                  country={country}
                  setCurrRegion={setCurrRegion}
                  setCurrProperty={setCurrProperty}
                  setCurrProperties={setCurrProperties}
                  setCountry={setCountry}
                  robotName={robotName}
                  setRobotName={setRobotName}
                  duplicates={duplicates}
                  setDuplicates={setDuplicates}
                  currRegion={currRegion}
                  currProperty={currProperty}
                  currProperties={currProperties}
                />
              )}
              {tabPage === 2 && (
                <SystemConfigurations
                  isSelectedRobotConnectedROS={isSelectedRobotConnectedROS}
                  handleShowDisableZedDiagnosticWarning={handleShowDisableZedDiagnosticWarning}
                  isSelectedRobotInUse={isSelectedRobotInUse}
                  uiParameters={uiParameters}
                  handleUiParametersChange={handleUiParametersChange}
                />
              )}
              {tabPage === 3 && (
                <HardwareConfigurations
                  isSelectedRobotConnectedROS={isSelectedRobotConnectedROS}
                  isSelectedRobotInUse={isSelectedRobotInUse}
                  uiParameters={uiParameters}
                  handleUiParametersChange={handleUiParametersChange}
                />
              )}
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <>
            <Button onClick={() => handleClose()} variant="outlined" color="primary">
              Cancel
            </Button>
            <Button autoFocus onClick={handleSave} variant="contained" color="secondary" disabled={!isFormUpdated}>
              Save
            </Button>
          </>
        </DialogActions>
        <ActionsDialog
          open={openContentInfoDialogue}
          dialogTitle={CONFIGURE_ROBOTS_INFO_DIALOGUE[tabPage]?.title}
          dialogBody={CONFIGURE_ROBOTS_INFO_DIALOGUE[tabPage]?.body}
          onClose={() => setOpenContentInfoDialogue(false)}
        />
        <UnsavedChangesDialog show={openUnsavedChangesDialog} onClose={(confirmClose) => handleConfirmClose(confirmClose)} />
      </Dialog>
      <ActionsDialog
        dialogTitle="To apply these changes, a restart is required. Would you like to reboot now? (The robot has to be Estopped)"
        open={openRestartDialog}
        actions={[
          {
            color: 'secondary',
            name: 'Restart',
            isButtonDisabled: !robotIsEstopped,
            handler: saveRobotConfigChangesAndClose
          },
          {
            color: '#555555',
            name: 'Cancel',
            handler: closeDialogueAndUpdatePortalRefs
          }
        ]}
      />
      <ActionsDialog
        dialogTitle="This will DISABLE ZED diagnostic systems. Are you sure you want to continue?"
        open={showDisableZedDiagnosticWarning}
        actions={[
          {
            color: 'secondary',
            name: 'Continue',
            handler: handleDisableZedDiagnosticConfirmation
          },
          {
            color: '#555555',
            name: 'Cancel',
            handler: handleDisableZedDiagnosticCancel
          }
        ]}
      />
      <ActionsDialog
        dialogTitle={ROSDisconnectedDialogTitle}
        open={openROSDisconnectedDialog}
        actions={[
          {
            color: '#555555',
            name: 'OK',
            handler: closeROSDisconnectDialog
          }
        ]}
      />
    </>
  );
});

export default ConfigureRobot;
